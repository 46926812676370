import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export const Keyword = props => (
  <SvgIcon {...props}>
    <g id="pen_empty">
      <path
        fill={'inherit'}
        d="M17.9,4.7l-2.2,2.1L17,8l0.1-0.1l1.4-1.4l0.2,0.2c0.1-0.3,0.3-0.6,0.5-0.8L17.9,4.7 M12.4,10.2L4,18.6v1.2h1.3
            l8.3-8.3L12.4,10.2 M17.9,2.3c0.3,0,0.5,0.1,0.8,0.3l2.5,2.5c0.4,0.4,0.4,1.2,0,1.5c-0.3,0.4-0.8,0.8-0.8,1.1
            c0,0.3,0.3,0.6,0.6,1.1c0.5,0.5,1.1,1,1,1.5c0,0.5-0.5,1.1-1.1,1.6l-4.4,4.4L15,15l4.5-4.5l-1.1-1.1L17,10.9l-4.1-4.1l4.3-4.1
            C17.4,2.4,17.7,2.3,17.9,2.3z M12.4,7.4l4,4.1L6.2,21.8H2v-4L12.4,7.4z"
      />
    </g>
  </SvgIcon>
)

export default Keyword
