import React from 'react'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const styles = () => ({
  root: {
    background: '#ff4444',
    color: '#fff',
    '&:hover, &:focus, &:active': {
      background: '#CC0000'
    }
  },
  deleteButton: {
    color: '#CC0000'
  }
})

class DeleteButton extends React.Component {
  state = {
    confirmButton: false
  }

  openConfirm() {
    const { time } = this.props
    this.setState(
      {
        confirmButton: true
      },
      () => {
        setTimeout(this.closeConfirm, time)
      }
    )
  }

  closeConfirm = () => {
    this.setState({
      confirmButton: false
    })
  }

  onConfirm = () => {
    typeof this.props.callback && this.props.callback()
  }

  render() {
    const { classes, children } = this.props
    const { confirmButton } = this.state
    if (confirmButton) {
      return (
        <Button
          onClick={() => {
            this.onConfirm()
          }}
          className={classNames(classes.root)}
        >
          Confirmar
        </Button>
      )
    } else {
      return (
        <Button
          className={classes.deleteButton}
          onClick={() => {
            this.openConfirm()
          }}
        >
          {children || 'Excluir'}
        </Button>
      )
    }
  }
}

export default withStyles(styles)(DeleteButton)
