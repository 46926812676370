import { history } from '../store'

const stack = () => {
  const getInitialState = () => {
    return {
      stack: {
        screens: []
      }
    }
  }

  const getState = () => {
    const { state = {} } = (history || {}).location || {}
    return { ...getInitialState(), ...state }
  }

  const active = () => {
    return !!getState().stack.screens.length
  }

  const getCurrentScreen = () => {
    const state = getState()
    const tail = state.stack.screens.length - 1
    return state.stack.screens[tail] || {}
  }

  const getPreviousScreen = () => {
    const state = getState()
    const prev = state.stack.screens.length - 2
    return state.stack.screens[prev] || {}
  }

  const getPathname = () => {
    const { pathname = {} } = (history || {}).location || {}
    return pathname || '/'
  }

  const push = screen => {
    const state = getState()
    const pathname = getPathname()
    history.replace(pathname, {
      ...state,
      stack: {
        ...state.stack,
        screens: [...state.stack.screens, { ...screen, shouldLog: true }]
      }
    })
  }

  const contentLogged = screen => {
    if (!screen || !screen.id) return

    const state = getState()
    const pathname = getPathname()

    history.replace(pathname, {
      ...state,
      stack: {
        ...state.stack,
        screens: state.stack.screens.map(item => {
          if (item && item.id && screen.id && item.id === screen.id) {
            item.shouldLog = false
          }
          return item
        })
      }
    })
  }

  const shouldLogContent = screen => {
    const state = getState()
    const content = state.stack.screens.find(item => (item || {}).id === (screen || {}).id) || null
    return !!(content && content.shouldLog)
  }

  const getBackScreen = state => {
    if (state.stack.screens.length <= 1) {
      return []
    } else {
      return state.stack.screens.slice(0, state.stack.screens.length - 1)
    }
  }

  const back = () => {
    const state = getState()
    const pathname = getPathname()

    history.replace(pathname, {
      ...state,
      stack: {
        ...state.stack,
        screens: [...getBackScreen(state)]
      }
    })
  }

  const clearStack = () => {
    const state = getState()
    const pathname = getPathname()

    history.replace(pathname, {
      ...state,
      stack: {
        ...state.stack,
        screens: []
      }
    })
  }

  return {
    push,
    back,
    clearStack,
    active,
    getCurrentScreen,
    getPreviousScreen,
    contentLogged,
    shouldLogContent
  }
}

export default stack()
