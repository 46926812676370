import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import pushRoutePersisted from './pushRoutePersisted'
import drawer from './drawer'
import contentScroll from './contentScroll'
import storage from 'redux-persist/lib/storage'
import { device } from '../utils'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import snackbar from './snackbar'
import searchMedication from './searchMedication'
import medicationList from './medicationList'

export default history => {
  const router = connectRouter(history)

  const persistRouterOnlyIos = () => {
    if (device.isIosStandaloneMode()) {
      return persistReducer(
        {
          key: 'INTERDRUGS_ROUTER',
          storage,
          stateReconciler: hardSet
        },
        router
      )
    }
    return router
  }

  return combineReducers({
    drawer,
    snackbar,
    medicationList,
    searchMedication,
    contentScroll: persistReducer(
      {
        key: 'INTERDRUGS_CONTENT_SCROLL',
        storage,
        stateReconciler: hardSet
      },
      contentScroll
    ),
    router: persistRouterOnlyIos(),
    pushRouterPersisted: pushRoutePersisted(history)
  })
}
