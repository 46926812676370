import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Query from 'react-apollo/Query'
import Divider from '@material-ui/core/Divider'
import Redirect from 'react-router-dom/Redirect'
import * as authService from '../services/auth'
import Snackbar from '@material-ui/core/Snackbar'
import OnlyOnline from '../components/OnlyOnline'
import PrescriptionsDialog from '../components/PrescriptionsDialog'
import { informationInteractionQueries } from '../graphql/queries'
import ErrorHandling from '../components/ErrorHandling'
import Tabs from '@material-ui/core/Tabs'
import SwipeableViews from 'react-swipeable-views'
import Tab from '@material-ui/core/Tab'
import PrintIcon from '@material-ui/icons/Print'
import { medicationListActions } from '../actions'
import { connect } from 'react-redux'
import Content from './Content'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import BulaInteracaoIcon from '../icons/svg/BulaInteracaoIcon'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    background: '#f7f7f7',
    maxWidth: '540px',
    '@media only print': {
      maxWidth: '900px',
      marginLeft: '25px',
      backgroundColor: 'red',
    },
  },
  appBar: {
    width: '100%',
    backgroundColor: '#5d5c5c',
    zIndex: theme.zIndex.drawer + 1,
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  toolBar: {
    padding: '0 8px',
  },
  menuLink: {
    display: 'flex',
    textDecoration: 'none',
    padding: '12px 16px',
    '& > svg': {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
    '&.menu-link-active': {
      background: '#eee',
      '& > div span': {
        color: '#0080E2',
      },
      '& > svg': {
        color: '#0080E2 !important',
      },
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    margin: '0',
  },
  toolbarUser: {
    color: 'inherit',
    fontSize: '.9rem',
    display: 'block',
  },
  toolbarMail: {
    color: 'inherit',
    fontSize: '.7rem',
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px',
  },
  childrenWrapper: {
    width: '100%',
    height: 'calc(100% - 64px)',
    '-webkit-overflow-scrolling': 'touch' /* Lets it scroll lazy */,
    overflow: 'auto',
    background: '#fff',
    '@media only screen and (max-width: 900px) and (orientation: landscape)': {
      height: 'calc(100% - 47px)',
    },
    '&.hiddenContainer': {
      display: 'none',
    },
  },
  singleMessage: {
    display: 'block',
    maxWidth: '250px',
    whiteSpace: 'normal',
    '& > span': {
      color: '#000',
      fontSize: '16px',
      fontWeight: '400',
    },
    '& > div': {
      color: '#000',
      fontSize: '14px',
      fontWeight: '400',
    },
    '& > div span': {
      color: '#9e9e9e',
      lineHeight: '1.4',
      fontWeight: '400',
    },
  },
  userHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  itemText: {
    '& > *': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  lockIcon: {
    marginRight: 5,
    fontSize: 18,
  },
  customTab: {
    flex: 1,
    height: '64px',
    fontSize: '16px',
    borderBottom: '#6EBD8F',
    width: '50%',
  },
  container: {
    '@media only print': {
      paddingTop: 0,
    },
  },
  titleInteraction: {
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  iconPrint: {
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
    color: '#6EBD8F !important',
  },
  interactionDetailItem: {
    orphans: 3,
    pageBreakBefore: 'always',
  },
  prescriptionSpan: {
    textDecoration: 'underline',
    textTransform: 'capitalize',
    display: 'block',
    marginBottom: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  simpleSpan: {
    textTransform: 'capitalize',
    display: 'block',
    marginBottom: 12,
  },
  secondPrescriptionSpan: {
    textDecoration: 'underline',
    textTransform: 'capitalize',
    display: 'block',
    marginBottom: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  secondSimpleSpan: {
    textTransform: 'capitalize',
    display: 'block',
    marginBottom: 12,
  },
  contentSpacing: {
    marginTop: '64px',
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-around'
  }
})

const CustomTabs = withStyles((theme) => {
  return {
    root: {
      width: '100%',
    },
    indicator: {
      background: '#6EBD8F',
    },
  }
})(Tabs)

class InformationInteraction extends React.Component {
  constructor(props) {
    super(props)
    this.contentWrapperRef = React.createRef()
    this.state = {
      openUpdatePassword: false,
      openEdit: false,
      snackMessage: '',
      selectedTab: 0,
      medicine1: '',
      medicine2: '',
      displayContent: false,
      dialogIsOpen: false,
      prescriptionContent: [],
    }
  }

  onOpenUpdatePassword = () => {
    this.setState({
      openUpdatePassword: true,
      openEdit: false,
    })
  }

  onOpenEdit = () => {
    this.setState({
      openEdit: true,
      openUpdatePassword: false,
    })
  }

  onCloseUpdatePassword = () => {
    this.setState({
      openUpdatePassword: false,
    })
  }

  onCloseEdit = () => {
    this.setState({
      openEdit: false,
    })
  }

  openDialog = () => {
    this.setState({
      dialogIsOpen: true,
    })
  }

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    })
  }

  renderSpinner = () => {
    const { classes } = this.props
    return <CircularProgress className={classes.progress} size={30} />
  }

  familyFormatCitation(family) {
    const familyTitle = family.split(':')
    return familyTitle[0]
  }

  familyFormatDescription(family) {
    const familyTitle = family.split(':')
    return familyTitle[1]
  }

  checkMedications(medications) {
    let auxMed = medications.split(',')

    if (auxMed[1] == '') {
      return auxMed[0]
    } else {
      return medications.replace(',', ' / ')
    }
  }

  handlePrescriptionOptions = (input, id, name) => {
    if ((input || []).length > 1) {
      this.openDialog()
    } else {
      this.setState({ displayContent: true })
      this.handleActivePrescription(id, name)
    }
  }

  handleActivePrescription = (id, name) => {
    this.props.addMedicineLeaflet({ id, name })
    this.setState({ displayContent: true })
  }

  handleContent = (id, nome, content) => {
    this.setState({ prescriptionsContent: content })
    this.handlePrescriptionOptions(content || [], id, nome)
  }

  backToInteraction = (value) => {
    this.setState({ selectedTab: value })
    this.setState({ displayContent: false })
  }

  renderInformation = (interaction) => {
    const { classes } = this.props
    if(!!interaction){
    return (
      <SwipeableViews
        axis={'x'}
        index={this.state.selectedTab}
        className={classes.container}
        onChangeIndex={(value) => this.setState({ selectedTab: value })}
      >
        <div hidden={this.state.selectedTab !== 0}>
          <div className={classes.header}>
            <div
              style={{ width: '75%', textTransform: 'uppercase', float: 'left', paddingLeft: '10px' }}
            >
              <h4>{(interaction[0] || {}).nome}</h4>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <PrintIcon
                className={classNames(classes.iconPrint, 'hidden-print')}
                onClick={() => {
                  window.print()
                }}
                color="secondary"
              />
            </div>
          </div>

          <div style={{ width: '100%', marginBottom: '60px' }}>
            <Divider/>
            <div style={{ paddingLeft: '20px', paddingRight: '12px' }}>
              {!!(interaction[0] || []).medicamentos && (
                <>
                  <h4 className={classes.titleInteraction}>Lista de Medicamentos </h4>
                  {this.props.prescriptionsList &&
                    this.props.prescriptionsList.map((item, index) => {
                      if (!!item.nome) {
                        if (item.conteudos.length > 1) {
                          return (
                            <>
                              <span
                                style={{ display: 'flex', alignItems: 'center', color: '#19A97A' }}
                                className={
                                  index === 0
                                    ? classes.prescriptionSpan
                                    : classes.secondPrescriptionSpan
                                }
                                onClick={() =>
                                  this.handleContent(
                                    ((item || {}).conteudos[0] || {})._id,
                                    ((item || {}).conteudos[0] || {}).principioAtivo,
                                    (item || {}).conteudos
                                  )
                                }
                              >
                                <BulaInteracaoIcon />
                                {item.nome.toLowerCase()}
                              </span>
                            </>
                          )
                        } else if (item.conteudos.length === 1) {
                          return (
                            <>
                              <span
                                style={{ display: 'flex', alignItems: 'center' }}
                                className={
                                  index === 0
                                    ? classes.prescriptionSpan
                                    : classes.secondPrescriptionSpan
                                }
                                onClick={() =>
                                  this.handleContent(
                                    ((item || {}).conteudos[0] || {})._id,
                                    ((item || {}).conteudos[0] || {}).principioAtivo,
                                    (item || {}).conteudos
                                  )
                                }
                              >
                                <BulaInteracaoIcon />
                                {item.nome.toLowerCase()}
                              </span>
                            </>
                          )
                        } else {
                          return (
                            <span
                              style={{ display: 'flex', alignItems: 'center' }}
                              className={
                                index === 0 ? classes.simpleSpan : classes.secondSimpleSpan
                              }
                            >
                              <BulaInteracaoIcon />
                              {item.nome.toLowerCase()}
                            </span>
                          )
                        }
                      }
                    })}
                  <PrescriptionsDialog
                    open={this.state.dialogIsOpen}
                    value={this.state.prescriptionsContent}
                    onClose={() => this.closeDialog()}
                    handleActiveContent={this.handleActivePrescription}
                  />
                  <Dialog
                    open={this.setState.dialogIsOpen}
                    keepMounted
                    onClose={this.closeDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {"Use Google's location service?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeDialog} color="primary">
                        Disagree
                      </Button>
                      <Button onClick={this.closeDialog} color="primary">
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}

              {!!(interaction[0] || {}).mecanismoProvavel && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>Resultado da interação</h4>
                  <span>{(interaction[0] || {}).mecanismoProvavel}</span>
                </div>
              )}

              {!!(interaction[0] || {}).probabilidade && (
                <>
                  <h4 className={classes.titleInteraction}>Probabilidade</h4>
                  <span>{(interaction[0] || {}).probabilidade}</span>
                </>
              )}

              {!!(interaction[0] || {}).gravidade && (
                <>
                  <h4 className={classes.titleInteraction}>Gravidade</h4>
                  <span>{(interaction[0] || {}).gravidade}</span>
                </>
              )}

              {!!(interaction[0] || {}).sugestaoManejo && (
                <>
                  <h4 className={classes.titleInteraction}>Sugestão de conduta</h4>
                  <span>{(interaction[0] || {}).sugestaoManejo}</span>
                </>
              )}

              {!!(interaction[0] || {}).tempoInicio && (
                <>
                  <h4 className={classes.titleInteraction}>Parâmetros de monitorização</h4>
                  <span>{(interaction[0] || {}).tempoInicio}</span>
                </>
              )}
           
            </div>

            <div style={{ paddingTop: '20px' }} />
          </div>
        </div>
        <div hidden={this.state.selectedTab !== 1}>
        <div className={classes.header}>
            <div
              style={{ width: '75%', textTransform: 'uppercase', float: 'left', paddingLeft: '10px' }}
            >
              <h4>{(interaction[0] || {}).nome}</h4>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <PrintIcon
                className={classNames(classes.iconPrint, 'hidden-print')}
                onClick={() => {
                  window.print()
                }}
                color="secondary"
              />
            </div>
          </div>
          <div id="printer" style={{ width: '100%', marginBottom: '60px' }}>
            <Divider/>

            <div style={{ paddingLeft: '20px', paddingRight: '12px' }}>
              {!!(interaction[0] || []).medicamentos && (
                <>
                  <h4 className={classes.titleInteraction}>Lista de Medicamentos </h4>
                  {this.props.prescriptionsList &&
                    this.props.prescriptionsList.map((item, index) => {
                      if (!!item.nome) {
                        if (item.conteudos.length > 1) {
                          return (
                            <>
                              <span
                                style={{ display: 'flex', alignItems: 'center', color: '#19A97A' }}
                                className={
                                  index === 0
                                    ? classes.prescriptionSpan
                                    : classes.secondPrescriptionSpan
                                }
                                onClick={() =>
                                  this.handleContent(
                                    ((item || {}).conteudos[0] || {})._id,
                                    ((item || {}).conteudos[0] || {}).principioAtivo,
                                    (item || {}).conteudos
                                  )
                                }
                              >
                                <BulaInteracaoIcon />
                                {item.nome.toLowerCase()}
                              </span>
                            </>
                          )
                        } else if (item.conteudos.length === 1) {
                          return (
                            <>
                              <span
                                style={{ display: 'flex', alignItems: 'center' }}
                                className={
                                  index === 0
                                    ? classes.prescriptionSpan
                                    : classes.secondPrescriptionSpan
                                }
                                onClick={() =>
                                  this.handleContent(
                                    ((item || {}).conteudos[0] || {})._id,
                                    ((item || {}).conteudos[0] || {}).principioAtivo,
                                    (item || {}).conteudos
                                  )
                                }
                              >
                                <BulaInteracaoIcon />
                                {item.nome.toLowerCase()}
                              </span>
                            </>
                          )
                        } else {
                          return (
                            <span
                              style={{ display: 'flex', alignItems: 'center' }}
                              className={
                                index === 0 ? classes.simpleSpan : classes.secondSimpleSpan
                              }
                            >
                              <BulaInteracaoIcon />
                              {item.nome.toLowerCase()}
                            </span>
                          )
                        }
                      }
                    })}
                </>
              )}

              {!!(interaction[0] || {}).efeito && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>Aplicabilidade</h4>
                  <span>{(interaction[0] || {}).efeito}</span>
                </div>
              )}

              {!!(interaction[0] || {}).familiaMed1 && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>
                    {'Medicamentos do grupo ' +
                      this.familyFormatCitation((interaction[0] || {}).familiaMed1)}
                  </h4>
                  <span>{this.familyFormatDescription((interaction[0] || {}).familiaMed1)}</span>
                </div>
              )}

              {!!(interaction[0] || {}).familiaMed2 && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>
                    {'Medicamentos do grupo ' +
                      this.familyFormatCitation((interaction[0] || {}).familiaMed2)}
                  </h4>
                  <span>{this.familyFormatDescription((interaction[0] || {}).familiaMed2)}</span>
                </div>
              )}

              {!!(interaction[0] || {}).mecanismoProvavel && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>Resultado da interação</h4>
                  <span>{(interaction[0] || {}).mecanismoProvavel}</span>
                </div>
              )}

              {!!(interaction[0] || {}).probabilidade && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>Probabilidade</h4>
                  <span>{(interaction[0] || {}).probabilidade}</span>
                </div>
              )}

              {!!(interaction[0] || {}).gravidade && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>Gravidade</h4>
                  <span>{(interaction[0] || {}).gravidade}</span>
                </div>
              )}

              {!!(interaction[0] || {}).sugestaoManejo && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>Sugestão de conduta</h4>
                  <span>{(interaction[0] || {}).sugestaoManejo}</span>
                </div>
              )}

              {!!(interaction[0] || {}).tempoInicio && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>Parâmetros de monitorização</h4>
                  <span>{(interaction[0] || {}).tempoInicio}</span>
                </div>
              )}

              {!!(interaction[0] || []).referencias.length && (
                <div className={classes.interactionDetailItem}>
                  <h4 className={classes.titleInteraction}>Referências</h4>
                  <span>
                    {(interaction[0] || []).referencias.length > 0
                      ? (interaction[0] || []).referencias.map((item) => {
                          return <li>{item}</li>
                        })
                      : 'Não informado'}
                  </span>
                </div>
              )}
            </div>
            <div style={{ paddingTop: '20px' }} />
          </div>
        </div>
      </SwipeableViews>
    )
                      }else{
                        return <></>
                      }
  }

  openSnackMessage = (message) => {
    this.setState({
      snackMessage: message,
    })
  }

  closeSnackMessage = () => {
    this.setState({
      snackMessage: '',
    })
  }

  convertToArray(term) {
    let arr = []
    arr = term.split(',')
    return arr
  }

  printDetalhada() {
    let content = document.getElementById('printer').innerHTML
    let tela_impressao = window.open('about:blank')
    tela_impressao.document.write(content)
    tela_impressao.window.print()
    tela_impressao.window.close()
  }

  render() {
    const { classes } = this.props
    if (authService.isDirectToken()) {
      return <Redirect to={'/'} />
    }
    return (
      <Query
        query={informationInteractionQueries.SEARCH_INFORMATION_INTERACTION}
        variables={{
          term: this.props.interactionsInformations.split(','),
        }}
      >
        {({ loading, error, data = {}, refetch, networkStatus }) => {
          if (error) {
            return (
              <ErrorHandling
                error={error}
                refetch={() => refetch()}
                networkStatus={networkStatus}
              />
            )
          }
          return (
            <div className={classNames(classes.root, 'transition-item detail-page')}>
              <AppBar position="absolute" className={classNames(classes.appBar, 'hidden-print')}>
                {!this.state.displayContent ? (
                  <CustomTabs
                    value={this.state.selectedTab}
                    onChange={(event, newValue) => this.backToInteraction(newValue)}
                    indicatorColor={'white'}
                    variant={'fullWidth'}
                    centered
                  >
                    <Tab className={classes.customTab} label="Resumo" />
                    <Tab className={classes.customTab} label="Detalhe" />
                  </CustomTabs>
                ) : (
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    onClick={() => {
                      this.setState({ displayContent: false })
                    }}
                    className={classNames(classes.menuButton)}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </AppBar>
              <main className={classes.content}>
                {!this.state.displayContent ? (
                  <>
                    <div className={classNames(classes.toolbar, 'hidden-print')} />
                    <OnlyOnline>
                      <div className={classNames(classes.childrenWrapper)}>
                        <div className={'container'}>
                          {loading
                            ? this.renderSpinner()
                            : this.renderInformation(data.completeMedicationsInteractions)}
                        </div>
                      </div>
                    </OnlyOnline>
                  </>
                ) : (
                  <div className={classNames(classes.contentSpacing)}>
                    <Content />
                  </div>
                )}
              </main>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3500}
                onClose={() => {
                  this.closeSnackMessage()
                }}
                open={!!this.state.snackMessage}
                ContentProps={{
                  'aria-describedby': 'login-error',
                }}
                message={<span id="login-error">{this.state.snackMessage}</span>}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedList: state.medicationList.selectedList,
    interactionsInformations: state.medicationList.interactionsInformations,
    interactionsResults: state.medicationList.interactionsResults,
    prescriptionsList: state.medicationList.prescriptionsList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetInformations: () => {
      dispatch(medicationListActions.resetInformations())
    },
    addMedicineLeaflet: (medicineLeaflet) => {
      dispatch(medicationListActions.addMedicineLeaflet(medicineLeaflet))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(InformationInteraction))
