import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import logoColor from '../icons/svg/logoColor.svg'

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main
  },
  icon: {
    width: 200,
    marginBottom: 10
  },
  title: {
    color: '#fff'
  }
})

const DirectAuthPrivacyError = props => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <Typography
        gutterBottom
        className={classes.title}
        style={{ fontSize: 16, textAlign: 'center' }}
      >
        Este conteúdo não pode ser exibido aqui.
      </Typography>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(DirectAuthPrivacyError)
