import * as utils from '../utils'
const { REACT_APP_API_BASE_URL } = process.env

export const refreshToken = ({ accessToken = '', refreshToken = '' }) => {
  return fetch(`${REACT_APP_API_BASE_URL}/refresh-token`, {
    method: 'POST',
    headers: {
      Authorization: `${accessToken}`,
      'refresh-token': refreshToken
    }
  })
}

export const login = ({ email = '', password = '', fingerprint = '', app = '' }) => {
  const params = {
    email,
    password,
    fingerprint,
    app
  }
  const searchParams = utils.fetch.searchParams(params)

  return fetch(`${REACT_APP_API_BASE_URL}/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: searchParams
  })
}

export const directAuth = ({ user = '', auth = '', cpf = '' }) => {
  const params = {
    user,
    auth,
    cpf
  }
  const searchParams = utils.fetch.searchParams(params)

  return fetch(`${REACT_APP_API_BASE_URL}/direct-auth`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'x-parent-referrer': document.referrer
    },
    body: searchParams
  })
}

export const refreshPassword = ({ email = '', password = '', newPassword = '' }) => {
  const params = {
    email,
    password,
    newPassword
  }
  const searchParams = utils.fetch.searchParams(params)

  return fetch(`${REACT_APP_API_BASE_URL}/refresh-password`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: searchParams
  })
}
