import gql from 'graphql-tag'

export const SEARCH_INFORMATION_INTERACTION = gql`
  query completeMedicationsInteractions($term: [String]!) {
    completeMedicationsInteractions(term: $term) {
      medicamentos
      classificacoesAtc
      referencias
      mecanismoProvavel
      sugestaoManejo
      nome
      tempoInicio
      efeito
      gravidade
      probabilidade
      familiaMed1
      familiaMed2
    }
  }
`
