import uuidv4 from 'uuid/v4'
import { getUserId, makeCookieStorage } from '../auth'

const SESSION_LIMIT = 3.6e6 // 1hr
export const SESSION_KEY = 'sessionId'

const cookieStorage = makeCookieStorage()

const persistSession = session => {
  try {
    cookieStorage.setItem(SESSION_KEY, JSON.stringify(session))
    return session
  } catch (error) {
    console.error(error)
    return null
  }
}

const extendSession = sessionId => {
  return persistSession({ sessionId, last: Date.now() })
}

const makeNewSession = () => {
  const userId = getUserId()
  const uuid = uuidv4()
  const last = Date.now()
  const sessionId = userId && uuid ? `${userId}.${uuid}` : ''

  if (!sessionId) return ''

  return { sessionId, last }
}

const sessionHasUserId = session => {
  const userId = getUserId()
  return ((session || {}).sessionId || '').includes(userId)
}

const getPersistedSession = () => {
  let persistedSession = null

  try {
    return (persistedSession = JSON.parse(cookieStorage.getItem(SESSION_KEY)) || null)
  } catch (error) {
    console.error(error)
  }

  return persistedSession
}

const getSession = () => {
  const persistedSession = getPersistedSession()

  return sessionHasUserId(persistedSession) ? persistedSession : persistSession(makeNewSession())
}

export const getSessionId = () => {
  const lastSession = getSession()
  const { sessionId = '', last } = lastSession
  const now = Date.now()

  const session =
    now - last < SESSION_LIMIT ? extendSession(sessionId) : persistSession(makeNewSession())

  return (session || {}).sessionId
}
