import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import AppRoutes from './routes/AppRoutes'
import NotificationDialog from './components/NotificationDialog'
import Button from '@material-ui/core/Button'
// import SessionControlModal from './components/SessionControlModal' // SESSION_CONTROL
import AppSnackbar from './components/AppSnackbar'
import { hotjar } from 'react-hotjar'

hotjar.initialize('2003313', '6')

let refreshing

class App extends Component {
  state = {
    newRegistration: null
  }
  onswUpdate = e => {
    if ((e.detail || {}).waiting) {
      this.setState({
        newRegistration: e.detail
      })
    }
  }
  updateSW = () => {
    this.state.newRegistration &&
      this.state.newRegistration.waiting &&
      this.state.newRegistration.waiting.postMessage({ action: 'skipWaiting' })
  }
  componentDidMount() {
    if (!navigator || !navigator.serviceWorker) return

    document.addEventListener('swUpdated', this.onswUpdate)

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing || !this.state.newRegistration) return
      window.location.reload()
      refreshing = true
    })
  }

  render() {
    return (
      <React.Fragment>
        <AppRoutes />
        <NotificationDialog />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!this.state.newRegistration}
          ContentProps={{
            'aria-describedby': 'login-error'
          }}
        >
          <SnackbarContent
            variant={'info'}
            message={'Há uma atualização disponível.'}
            action={[
              <Button variant={'flat'} color={'secondary'} onClick={this.updateSW}>
                Atualizar
              </Button>
            ]}
          />
        </Snackbar>
        <AppSnackbar />
        {/* <SessionControlModal />SESSION_CONTROL */}
      </React.Fragment>
    )
  }
}

export default App
