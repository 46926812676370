import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export const FontDecrease = props => (
  <SvgIcon {...props}>
    <path
      fill={'inherit'}
      d="M14.7,18.8H9.4l-1.2,3.3H6.5l4.8-12.6h1.5l4.8,12.6h-1.7L14.7,18.8z M9.9,17.4h4.3L12,11.5
	L9.9,17.4z"
    />
  </SvgIcon>
)

export default FontDecrease
