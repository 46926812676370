import { snackbarActions } from '../actions'
import { HIDE_SNACKBAR } from '../actions/snackbar'
const { SHOW_SNACKBAR } = snackbarActions

const initialState = {
  shouldShowSnackbar: false,
  message: null
}

const statuses = (state = initialState, action = '') => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return { ...state, shouldShowSnackbar: true, message: action.message }
    case HIDE_SNACKBAR:
      return { ...state, shouldShowSnackbar: false, message: null }
    default:
      return state
  }
}

export default statuses
