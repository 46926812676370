import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Account from '@material-ui/icons/Person'
import Lock from '@material-ui/icons/Lock'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Redirect from 'react-router-dom/Redirect'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import logoSvg from '../../icons/svg/logo.svg'
import UpdatePasswordDialog from '../../components/UpdatePasswordDialog'
import UpdatePasswordAlertDialog from '../../components/UpdatePasswordAlertDialog'
import CookieAlertDialog from '../../components/CookieAlertDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import withRouter from 'react-router-dom/withRouter'
import Link from 'react-router-dom/Link'
import FingerPrint from 'fingerprintjs2'
import * as API from '../../api'
import * as authService from '../../services/auth'

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    flex: 1,
    flexDirection: 'column',
    // backgroundImage: 'linear-gradient(234deg, #5d5c5c, #325388)',
    '@media only screen and (max-height: 420px)': {
      justifyContent: 'normal',
      overflow: 'auto',
    },
  },
  actionsWrapper: {
    '@media only screen and (max-height: 420px)': {
      paddingBottom: '40px !important',
    },
  },
  formWrapper: {
    '@media only screen and (max-height: 420px)': {
      paddingTop: '40px !important',
    },
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '85%',
    textAlign: 'center',
    maxWidth: 320,
  },
  logo: {
    marginBottom: 20,
    maxWidth: '75%',
  },
  link: {
    color: '#5D5C5C',
    textAlign: 'center',
    display: 'block',
    opacity: 0.8,
    textDecoration: 'none',
    fontSize: 14,
    margin: '10px auto 10px',
  },
  createAccountWrapper: {
    color: 'rgba(255,255,255,.8)',
    fontSize: 13,
  },
  createAccountDivider: {
    background: '#5D5C5C',
    height: 1,
    opacity: 0.2,
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  spanText: {
    color: '#5D5C5C',
  },
  createAccountLink: {
    textTransform: 'uppercase',
    color: '#5D5C5C',
    textDecoration: 'none',
    fontWeight: 'bold',
    margin: 8,
  },
  passWrapper: {
    position: 'relative',
    display: 'flex',
  },
  passIcon: {
    position: 'absolute',
    color: '#5D5C5C',
    top: '35%',
    right: '6%',
  },
  versionLabel: {
    color: '#5D5C5C',
    fontSize: '13px',
  },
})

const TextFieldLogin = withStyles({
  root: {
    marginTop: 10,
    marginBottom: 10,
    '& > *, &:hover > *, &:focus > *': {
      color: '#5D5C5C',
    },
    '& > div': {
      '&::before': {
        borderBottom: '1px solid #5D5C5C !important',
      },
      '&::after': {
        borderBottom: '1px solid #5D5C5C !important',
      },
    },
  },
})(TextField)

const ButtonLogin = withStyles({
  root: {
    marginTop: 20,
    fontSize: 16,
    padding: '.7em .4em',
    background: '#6EBD8F !important',
  },
  disabled: {
    background: '#A7D4B9 !important',
    color: '#5D5C5C !important',
  },
  '&, &:hover': {
    background: '#00A97A',
    color: '#3A3939',
  },
})(Button)

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      messageError: '',
      loading: false,
      openUpdatePassword: false,
      openUpdatePasswordAlertDialog: false,
      openCookieAlertDialog: false,
      isPasswordShow: false,
    }
    this.loginInputRef = React.createRef()
  }

  setLoading = (loading, callback) => {
    if (typeof callback !== 'function') {
      this.setState({ loading: !!loading })
    } else {
      this.setState({ loading: !!loading }, callback)
    }
  }

  togglePasswordVisibility = () => {
    const { isPasswordShow } = this.state
    this.setState({ isPasswordShow: !isPasswordShow })
  }

  login = ({ email, password, fingerprint }) => {
    const app = 'interdrugs'
    if ('cookieEnabled' in navigator && !navigator.cookieEnabled) {
      this.onOpenCookieAlertDialog()
      return null
    }

    this.setLoading(true, () => {
      API.auth
        .login({
          email,
          password,
          fingerprint,
          app,
        })
        .then(async (response) => {
          const { ok, status } = response
          if (ok) {
            if (status === 205) {
              this.onOpenUpdatePasswordAlert()
              return this.setLoading(false)
            }
            response
              .json()
              .then((data) => {
                const { token, refreshToken, ssoToken, expired } = data

                if (expired) {
                  this.setSnackbarMessage('Sua conta está expirada.')
                } else {
                  authService.login({ token, refreshToken, ssoToken })
                }

                this.setLoading(false)
              })
              .catch((err) => {
                if (status === 401) {
                  this.setSnackbarMessage('Usuário ou senha inválidos')
                } else {
                  this.setSnackbarMessage('Estamos com problemas, tente mais tarde.')
                }
                this.setLoading(false)
              })
          } else {
            if (status === 401) {
              this.setSnackbarMessage(await response.text())
            } else {
              this.setSnackbarMessage('Estamos com problemas, tente mais tarde.')
            }
            this.setLoading(false)
          }
        })
        .catch((err) => {
          this.setSnackbarMessage('Estamos com problemas, tente mais tarde.')
          this.setLoading(false)
        })
    })
  }
  onOpenUpdatePassword = () => {
    this.setState({
      openUpdatePassword: true,
      openUpdatePasswordAlertDialog: false,
    })
  }

  onOpenCookieAlertDialog = () => {
    this.setState({
      openCookieAlertDialog: true,
    })
  }

  onOpenUpdatePasswordAlert = () => {
    this.setState({
      openUpdatePassword: false,
      openUpdatePasswordAlertDialog: true,
    })
  }

  onCloseUpdatePassword = () => {
    this.setState({
      openUpdatePassword: false,
    })
  }

  onCloseCookieAlertDialog = () => {
    this.setState({
      openCookieAlertDialog: false,
    })
  }

  changeEmail = (e) => {
    this.setState({ email: e.target.value })
  }

  changePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  clearErrorMessage = () => {
    this.setState({
      messageError: '',
    })
  }

  setSnackbarMessage = (message) => {
    this.setState({
      messageError: message,
    })
  }

  render() {
    const { classes, className } = this.props
    const {
      email,
      loading,
      password,
      messageError,
      openUpdatePassword,
      openUpdatePasswordAlertDialog,
      openCookieAlertDialog,
      isPasswordShow,
    } = this.state

    if (authService.getAccessToken()) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: {
              fromLogin: true,
            },
          }}
        />
      )
    }

    return (
      <main className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose={() => {
            this.clearErrorMessage()
          }}
          open={!!messageError}
          ContentProps={{
            'aria-describedby': 'login-error',
          }}
          message={<span id="login-error">{messageError}</span>}
        />
        <React.Fragment>
          <form
            noValidate
            className={classNames(classes.container)}
            disabled={!password || !email || loading}
            onSubmit={(e) => {
              e.preventDefault()
              new FingerPrint().get((fingerprint) => {
                this.login({
                  email,
                  password,
                  fingerprint,
                })
              })
            }}
          >
            <div className={classes.formWrapper}>
              <img src={logoSvg} className={classes.logo} alt={'Logo Interdrugs'} />
              <TextFieldLogin
                placeholder="Usuário"
                value={email}
                type={'email'}
                fullWidth
                onChange={this.changeEmail}
                autoFocus
                InputProps={{
                  ref: this.loginInputRef,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Account style={{ fontSize: 14 }} />
                    </InputAdornment>
                  ),
                }}
              />

              <div className={classes.passWrapper}>
                <TextFieldLogin
                  className={classNames(classes.textField, className)}
                  placeholder="Senha"
                  fullWidth
                  value={password}
                  onChange={this.changePassword}
                  InputProps={{
                    type: isPasswordShow ? 'text' : 'password',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock style={{ fontSize: 14 }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <i className={classes.passIcon} onClick={this.togglePasswordVisibility}>
                  {isPasswordShow ? (
                    <Visibility style={{ fontSize: 18 }} />
                  ) : (
                    <VisibilityOff style={{ fontSize: 18 }} />
                  )}
                </i>
              </div>
            </div>

            <ButtonLogin
              variant="contained"
              fullWidth
              type={'submit'}
              disabled={!password || !email || loading}
            >
              {loading ? (
                <CircularProgress color={'primary'} size={24} className={classes.buttonProgress} />
              ) : (
                <span>Entrar</span>
              )}
            </ButtonLogin>
          </form>
          <UpdatePasswordDialog
            firstLogin
            open={openUpdatePassword}
            email={email}
            password={password}
            onSuccess={(message, { newPassword }) => {
              this.setSnackbarMessage(message)
              this.onCloseUpdatePassword()
              new FingerPrint().get((fingerprint) => {
                this.login({
                  email,
                  password: newPassword,
                  fingerprint,
                  app: 'interdrugs',
                })
              })
            }}
            onClose={() => {
              this.onCloseUpdatePassword()
            }}
          />
          <UpdatePasswordAlertDialog
            firstLogin
            open={openUpdatePasswordAlertDialog}
            onClose={() => {
              this.onOpenUpdatePassword()
            }}
          />

          <CookieAlertDialog
            firstLogin
            open={openCookieAlertDialog}
            onClose={() => {
              this.onCloseCookieAlertDialog()
            }}
          />
          <div className={classNames(classes.actionsWrapper, classes.container)}>
            <a
              className={classes.link}
              target="_blank"
              href="//atendimento.manole.com.br/recuperar-senha/"
              rel="noopener noreferrer"
            >
              Esqueceu sua senha?
            </a>
            <div className={classes.createAccountWrapper}>
              <div className={classes.createAccountDivider} />
              <span className={classes.spanText}>Não tem uma conta? </span>
              <a
                className={classes.createAccountLink}
                target="_blank"
                href="//www.informed.digital/cadastro/"
                rel="noopener noreferrer"
              >
                Criar conta
              </a>
            </div>
          </div>
          <p className={classes.versionLabel}>versão {process.env.REACT_APP_PACKAGE_VERSION}</p>
        </React.Fragment>
      </main>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Login))
)
