export const ADD_MEDICATION = 'ADD_MEDICATION'
export const ADD_MEDICINELEAFLET = 'ADD_MEDICINELEAFLET'
export const REMOVE_MEDICINELEAFLET = 'REMOVE_MEDICINELEAFLET'
export const REMOVE_MEDICATION = 'REMOVE_MEDICATION'
export const CLEAR_MEDICATION_LIST = 'CLEAR_MEDICATION_LIST'
export const CHANGE_SHOW_SEARCH_BAR = 'CHANGE_SHOW_SEARCH_BAR'
export const INTERACTIONS_CONSULT = 'INTERACTIONS_CONSULT'
export const CHANGE_INTERACTIONS_CONSULT = 'CHANGE_INTERACTIONS_CONSULT'
export const INTERACTION_INFORMATIONS_CONSULT = 'INTERACTION_INFORMATIONS_CONSULT'
export const CHANGE_INTERACTIONS_INFORMATIONS = 'CHANGE_INTERACTIONS_INFORMATIONS'
export const STORE_INTERACTIONS_INFORMATIONS = 'STORE_INTERACTIONS_INFORMATIONS'

export const addMedicineLeaflet = medicineLeaflet => {
  return dispatch => {
    dispatch({ type: ADD_MEDICINELEAFLET, medicineLeaflet })
  }
}

export const removeMedicineLeaflet = medicineLeaflet => {
  return dispatch => {
    dispatch({ type: REMOVE_MEDICINELEAFLET, medicineLeaflet })
  }
}

export const addMedication = medication => {
  return dispatch => {
    dispatch({ type: ADD_MEDICATION, medication })
  }
}

export const removeMedication = medication => {
  return dispatch => {
    dispatch({ type: REMOVE_MEDICATION, medication })
  }
}

export const clearMedicationList = () => {
  return dispatch => {
    dispatch({ type: CLEAR_MEDICATION_LIST })
  }
}

export const changeShowSearchBar = value => {
  return dispatch => {
    dispatch({ type: CHANGE_SHOW_SEARCH_BAR, value })
  }
}

export const interactionsConsult = medications => {
  return dispatch => {
    dispatch({ type: INTERACTIONS_CONSULT, medications })
  }
}
export const changeConsultInteraction = () => {
  return dispatch => {
    dispatch({ type: CHANGE_INTERACTIONS_CONSULT })
  }
}

export const interactionsInformationsConsult = informations => {
  return dispatch => {
    dispatch({ type: INTERACTION_INFORMATIONS_CONSULT, informations })
  }
}
export const resetInformations = () => {
  return dispatch => {
    dispatch({ type: CHANGE_INTERACTIONS_INFORMATIONS })
  }
}

export const storeInformations = prescription => {
  return dispatch => {
    dispatch({ type: STORE_INTERACTIONS_INFORMATIONS, prescription })
  }
}
