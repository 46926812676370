import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import MedicationsPlaceholderImage from '../../icons/svg/im_placeholder.svg'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '100%',
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20
  },
  icon: {
    fontSize: 80,
    color: theme.palette.primary.main
  },
  placeholderTitle: {
    color: '#979797',
    fontWeight: 400,
    maxWidth: '90%'
  },
  placeholderText: {
    fontSize: 16,
    maxWidth: 250,
    color: '#5d5c5c',
    fontWeight: 'bold'
  },
  placeholderImage: {
    maxWidth: '100%',
    marginBottom: 36,
    width: 80,
    '& .cls-1': {
      opacity: 0.398
    },
    '& .cls-2': {
      fill: '#979797'
    },
    '& .cls-3': {
      fill: '#f2f2f2'
    },
    '& .cls-4, & .cls-5': {
      stroke: 'none'
    },
    '& .cls-5': {
      fill: '#5d5c5c'
    }
  }
})

const FavoritePlaceholder = ({ classes }) => {
  return (
    <div className={classes.root}>
      <img
        className={classes.placeholderImage}
        src={MedicationsPlaceholderImage}
        alt={'Sua lista de medicamentos está vazia'}
      />
      <Typography variant={'title'} gutterBottom className={classes.placeholderTitle}>
        Insira medicamentos à lista usando o campo de busca acima
      </Typography>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(FavoritePlaceholder)
