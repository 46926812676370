import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import logoImage from '../../icons/svg/interdrugsLogo.svg'
import classNames from 'classnames'
import NotificationButton from './NotificationButton'
import withStyles from '@material-ui/core/styles/withStyles'
import CustomSearchBar from '../Medication/SearchBar/CustomSearchbar'
import SwitchDesktopMobile from '../../components/SwitchDesktopMobile'

const styles = theme => ({
  appBar: {
    backgroundColor: '#5D5C5C',
    zIndex: theme.zIndex.drawer + 1,
    '@media only print': {
      display: 'none'
    }
  },
  menuButton: {
    margin: '0 8px'
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px'
  }
})

class AppBarLayout extends React.PureComponent {
  render() {
    const { classes, handleMenuOpen, handleDrawerMessageOpen } = this.props
    return (
      <>
        <SwitchDesktopMobile mobileComponent={CustomSearchBar} />
        <AppBar position="absolute" className={classNames(classes.appBar)}>
          <Toolbar disableGutters={true} className={'container yAxisCleared'}>
            <IconButton
              color="inherit"
              aria-label="Abrir menu"
              onClick={handleMenuOpen}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="title" color="inherit" noWrap>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={logoImage} height="35px" style={{ display: 'block', marginBottom: '-8px' }} />
              </div>
            </Typography>
            {/* <div className={classes.toolBarRight}>
              <NotificationButton
                color="inherit"
                aria-label="Abrir Notificações"
                onClick={handleDrawerMessageOpen}
              />
            </div> */}
          </Toolbar>
        </AppBar>
      </>
    )
  }
}

export default withStyles(styles, { withTheme: true })(AppBarLayout)
