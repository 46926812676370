import escapeStringRegexp from 'escape-string-regexp'
import { getSessionId } from './session'
import * as authService from '../auth'
import { device } from '../../utils'
import { insertLoggerToken } from './local'
const { REACT_APP_LOGGER_URL } = process.env

/**
 * @param { object } payload - Contem informacoes sobre o evento disparado
 * @param { string } payload.evento - Nome do evento disparado (busca, acesso, visualizacao, inclusao, remocao, atualizacao)
 * @param { string } payload.contexto - Contexto do evento (conteudo, favoritos, anotacoes)
 * @param { string } payload.origem - Contexto do evento (busca, favoritos, anotacoes)
 * @param { string } payload.tipo - Tipo do conteudo (aula, capitulo...)
 * @param { string } payload.conteudo - Id do conteúdo (referência do mongo)
 * @param { string } payload.conteudoOrigem - Id do conteúdo de origem (referência do mongo)
 * @param { string } payload.score - Score do elastic search
 * @param { string } payload.posicao - Posicao na lista de resultados de busca
 * @param { string } payload.correspondencias - Número de resultados de busca
 * @param { string } payload.termo - Termo buscado
 * @param { string } payload.usuario - Id do usuario
 * @param { string } payload.sessao - Representa a sessão do usuario no aplicativo
 * @param { array } payload.medicamentos - Representa os ids dos nomes_dcb
 */

const makeRequest = (payload = {}) => {
  const loggerRequest = new XMLHttpRequest()
  if (!payload.origem && !payload.contexto && payload.evento === 'visualizacao') {
    const origin = 'url'
    payload.origem = origin
    payload.contexto = origin
  }
  const params = Object.entries(payload).reduce(
    (params, [key, value]) => (value || value === 0 ? { ...params, [key]: value } : params),
    {}
  )

  params.sessao = getSessionId()
  params.plataforma = device.getPlatform()
  const data = JSON.stringify(params)
  const token = authService.getAccessToken()

  loggerRequest.open('POST', `${REACT_APP_LOGGER_URL}/register`, true)
  loggerRequest.setRequestHeader('Content-Type', 'application/json')
  loggerRequest.setRequestHeader('Authorization', `${token}`)
  loggerRequest.setRequestHeader('cache-control', 'no-cache')

  loggerRequest.send(data)
}

export const onSearch = ({ termo, correspondencias, filtro, filtroLeitura }) => {
  makeRequest({
    termo,
    correspondencias,
    contexto: 'conteudo',
    evento: 'busca',
    filtro,
    filtroLeitura,
    app: 'interdrugs',
  })
}

/**
 * @param { object } payload - Contem informacoes sobre o evento disparado
 * @param { string } payload.evento - Nome do evento disparado (inclusao, remocao, atualizacao)
 * @param { string } payload.contexto - Contexto do evento (conteudo, favoritos, anotacoes)
 * @param { string } payload.conteudo - Id do conteúdo (referência do mongo)
 * @param { string } payload.tipo - Tipo do conteudo (aula, capitulo...)
 */
export const onInteract = ({ evento, contexto, conteudo, tipo }) => {
  makeRequest({ evento, contexto, conteudo, tipo, app: 'interdrugs' })
}

export const onClickItem = () => {}

export const onShowContent = ({
  termo,
  correspondencias,
  posicao,
  score,
  conteudo,
  tipo,
  origem,
  contexto,
  conteudoOrigem,
  filtro,
  filtroLeitura,
}) => {
  const payload = {
    evento: 'visualizacao',
    termo,
    correspondencias,
    posicao,
    score,
    conteudo,
    tipo,
    origem,
    contexto,
    conteudoOrigem,
    filtro,
    filtroLeitura,
    app: 'interdrugs',
  }

  payload.sessao = getSessionId()
  payload.plataforma = device.getPlatform()

  insertLoggerToken(payload)
  makeRequest(payload)
}

export const onAnalyzeMedicationsInteractions = ({ medicamentos, correspondencias }) => {
  const payload = {
    evento: 'consulta',
    contexto: 'medicamento',
    correspondencias,
    medicamentos,
    app: 'interdrugs',
  }

  payload.sessao = getSessionId()
  payload.plataforma = device.getPlatform()

  insertLoggerToken(payload)
  makeRequest(payload)
}

export const onShowInteractionResult = ({ medicamentos, correspondencias, conteudo, posicao }) => {
  const payload = {
    evento: 'visualizacao',
    origem: 'consulta',
    contexto: 'medicamento',
    correspondencias,
    medicamentos,
    conteudo,
    posicao,
    app: 'interdrugs',
  }

  payload.sessao = getSessionId()
  payload.plataforma = device.getPlatform()

  insertLoggerToken(payload)
  makeRequest(payload)
}
