import { onError } from 'apollo-link-error'
import * as authService from '../services/auth'

window.refreshingToken = false

const unauthorized = () => {
  authService.onLogout()
  window.location.pathname = '/'
}

const linkError = () => {
  return onError(({ graphQLErrors, ...rest }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }) => {
        if (message === 'Unauthorized') {
          return unauthorized()
        }
      })
  })
}

export default linkError
