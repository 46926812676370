import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import withRouter from 'react-router-dom/withRouter'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import OnlyOnline from './OnlyOnline'
import Slide from '@material-ui/core/Slide'
import { contentQueries } from '../graphql/queries'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import classNames from 'classnames'
import ReactPlaceholder from 'react-placeholder'
import Info from '@material-ui/icons/Info'
import NoteIconToolbar from '../components/NoteIconToolbar'
import ToggleFavoriteButton from '../components/ToggleFavoriteButton'
import ContentInfoDialog from '../components/ContentInfoDialog'
import FontZoom from '../components/FontZoom'
import RenderContent from '../components/RenderContent'
import Query from 'react-apollo/Query'
import NoteDialog from '../components/NoteDialog'
import contentHistoryStack from '../services/contentHistoryStack'
import AuthorDialog from '../components/AuthorDialog'
import ImageZoomDialog from '../components/ImageZoomDialog'
import Hammer from 'react-hammerjs'
import * as textUtils from '../utils/text'
import * as loggerClient from '../services/loggerClient'

const styles = theme => ({
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  toolbarBottom: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    background: '#efefef',
    color: '#504f51',
    padding: 8,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    '& > .container': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  fakeToolbarBottom: {
    marginTop: 16
  },
  previousScreen: {
    flex: 1,
    textTransform: 'uppercase',
    fontSize: 20,
    fontWeight: 'bold',
    display: 'block',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  content: {
    minHeight: 60,
    padding: 0,
    position: 'relative'
  },
  toolBar: {
    padding: '0 8px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px'
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  appBar: {
    backgroundColor: '#5d5c5c',
    zIndex: theme.zIndex.drawer + 1
  },
  disclaimer: {
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    paddingBottom: 36
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class ContentStackModal extends React.Component {
  constructor(props) {
    super(props)

    this.contentStackWrapperRef = React.createRef()
    this.state = {
      open: false,
      messagesOpen: false,
      showNote: false,
      showAuthor: false,
      showZoomImage: false,
      pointerType: undefined,
      showInfo: false
    }
  }

  changeNote = anotacao => {
    this.setState({ anotacao })
  }

  closeZoomImage = () => {
    this.setState({
      showZoomImage: false
    })
  }

  openErrorMessage = message => {
    this.setState({
      errorMessage: message
    })
  }

  closeInfo = () => {
    this.setState({
      showInfo: false
    })
  }

  openInfo = () => {
    this.setState({
      showInfo: true
    })
  }

  closeAuthor = () => {
    this.setState({
      showAuthor: false
    })
  }

  showAuthor = id => {
    this.setState({
      showAuthor: id
    })
  }

  openNoteDialog = () => {
    this.setState({
      showNote: true
    })
  }

  closeNoteDialog = () => {
    this.setState({
      showNote: false
    })
  }

  getContainerClasses = error => {
    const { classes = {} } = this.props
    const containerClasses = error ? 'errorContainer' : 'contentContainer'
    return classes[containerClasses]
  }

  componentDidMount() {
    const { location = {} } = this.props
    const { state = {} } = location
    if (state.openNotes) {
      setTimeout(() => {
        this.openNoteDialog()
      }, 300)
      this.props.history.replace(location.path, { ...state, openNotes: false })
    }

    this.historyListener = this.props.history.listen((newHistory, action) => {
      const $wrapper = document.getElementById('Dialog-Content-Wrapper')
      $wrapper && $wrapper.scrollTo(0, 0)
    })
  }

  componentWillUnmount() {
    this.historyListener && this.historyListener()
  }

  logContent = ({ content }) => {
    const { location = {} } = this.props
    const { state: locationState = {} } = location
    const currentScreen = contentHistoryStack.getCurrentScreen()

    if (contentHistoryStack.shouldLogContent(currentScreen)) {
      contentHistoryStack.contentLogged(currentScreen)
      loggerClient.onShowContent({
        termo: locationState.termo,
        correspondencias: locationState.correspondencias,
        posicao: locationState.posicao,
        score: locationState.score,
        conteudo: currentScreen.id,
        conteudoOrigem: contentHistoryStack.getPreviousScreen().id || this.props.match.params.id,
        tipo: content.tipo,
        origem: locationState.from,
        contexto: locationState.contexto
      })
    }
  }

  render() {
    const {
      value,
      searchOrder,
      classes,
      message,
      currentContentStackId,
      currentContent = '',
      ...other
    } = this.props
    if (!currentContentStackId || !currentContent) return null

    const currentContentTitle = currentContent.titulo || currentContent.principioAtivo || ''
    const previousContentId = contentHistoryStack.getPreviousScreen().id

    return (
      <Query
        query={contentQueries.GET_CONTENT}
        fetchPolicy={'cache-and-network'}
        variables={{ id: currentContentStackId }}
        onCompleted={(data = {}) => {
          const { content = {} } = data
          this.logContent({ content })
        }}
      >
        {({ loading, error, data = {}, refetch, networkStatus }) => {
          return (
            <Dialog
              maxWidth="xs"
              fullScreen
              onEntering={this.handleEntering}
              aria-labelledby="confirmation-dialog-title"
              TransitionComponent={Transition}
              className={classes.root}
              {...other}
            >
              <AppBar className={classes.appBar}>
                <Toolbar disableGutters={true} className={classNames(classes.toolbar, 'container')}>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      contentHistoryStack.clearStack()
                    }}
                    aria-label="Close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <div className={classes.toolBarRight}>
                    <IconButton
                      color="inherit"
                      style={{ color: loading || error ? 'rgba(255,255,255,.4)' : '#fff' }}
                      aria-label="open drawer"
                      className={classNames(classes.menuButton)}
                      disabled={loading || error}
                      onClick={() => {
                        this.openInfo()
                      }}
                    >
                      <Info />
                    </IconButton>

                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      style={{ color: loading || error ? 'rgba(255,255,255,.4)' : '#fff' }}
                      onClick={() => {
                        this.openNoteDialog()
                      }}
                      disabled={loading || error}
                      className={classNames(classes.menuButton)}
                    >
                      <NoteIconToolbar
                        id={currentContentStackId}
                        content={(data || {}).content}
                        disabled={loading || error}
                      />
                    </IconButton>

                    <FontZoom disabled={loading || error} />
                    <ToggleFavoriteButton
                      id={currentContentStackId}
                      content={(data || {}).content}
                      disabled={loading || error}
                    />
                  </div>
                </Toolbar>
              </AppBar>
              <OnlyOnline>
                <DialogContent
                  id="Dialog-Content-Wrapper"
                  ref={this.contentStackWrapperRef}
                  className={classNames(classes.content)}
                >
                  <div className={classes.toolbar} />
                  <div id="Stack-Content-Wrapper" className={classNames(classes.childrenWrapper)} style={{ marginBottom: '32px' }}>
                    <Hammer
                      onTap={e => {
                        if (e.target.tagName === 'IMG') {
                          this.setState({ showZoomImage: e.target, pointerType: e.pointerType })
                        }
                        if ('content' in e.target.dataset) {
                          contentHistoryStack.push({ id: e.target.dataset.href })
                        }
                      }}
                    >
                      <div
                        id={'Content-Stack-Container'}
                        className={classNames('container', this.getContainerClasses(error))}
                      >
                        <RenderContent
                          queryInfos={{ loading, error, data, refetch, networkStatus }}
                          contentId={currentContentStackId}
                          isStack
                          handleAuthorClick={id => {
                            this.showAuthor(id)
                          }}
                        />
                        <Divider />
                        <div className={classes.disclaimer}>
                          <Typography style={{ fontSize: 16, color: '#757575' }}>
                            {(data.metatag || {}).valor}
                          </Typography>
                          <Typography gutterBottom style={{ fontSize: 16, color: '#757575' }}>
                            Ref: {this.props.match.params.id}
                          </Typography>
                        </div>
                      </div>
                    </Hammer>
                  </div>
                </DialogContent>
                <div className={classNames(classes.toolbar, classes.fakeToolbarBottom)} />
                <Toolbar className={classNames(classes.toolbarBottom)}>
                  <div className="container" style={{ maxWidth: '100%' }}>
                    <IconButton
                      color="inherit"
                      aria-label="Voltar"
                      onClick={() => {
                        contentHistoryStack.back()
                      }}
                      className={classNames(classes.menuButton)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    <div className={classes.previousScreen}>
                      {!!previousContentId ? (
                        <Query
                          query={contentQueries.GET_CONTENT_TITLE}
                          fetchPolicy={'cache-and-network'}
                          variables={{
                            id: previousContentId
                          }}
                        >
                          {({ loading, error, data = {} }) => {
                            const content = (data || {}).content || {}
                            const contentTitle = content.titulo || content.principioAtivo || ''
                            if (loading || error) {
                              return (
                                <ReactPlaceholder
                                  children={<div />}
                                  showLoadingAnimation={true}
                                  type="text"
                                  ready={false}
                                  rows={1}
                                />
                              )
                            }

                            if (contentTitle) {
                              return (
                                <React.Fragment>
                                  {textUtils.truncateEllipsis(contentTitle, 30)}
                                </React.Fragment>
                              )
                            }
                            return null
                          }}
                        </Query>
                      ) : (
                        <React.Fragment>
                          {textUtils.truncateEllipsis(currentContentTitle, 30)}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </Toolbar>
              </OnlyOnline>
              <AuthorDialog
                open={this.state.showAuthor}
                onClose={() => {
                  this.closeAuthor()
                }}
              />

              <ImageZoomDialog
                open={!!this.state.showZoomImage}
                image={this.state.showZoomImage}
                pointerType={this.state.pointerType}
                onClose={() => {
                  this.closeZoomImage()
                }}
              />

              <NoteDialog
                open={this.state.showNote}
                contentId={currentContentStackId}
                contentType={((data || {}).content || {}).tipo}
                onClose={() => {
                  this.closeNoteDialog()
                }}
              />

              <ContentInfoDialog
                open={this.state.showInfo}
                contentId={currentContentStackId}
                content={(data || {}).content || {}}
                onClose={() => {
                  this.closeInfo()
                }}
              />
            </Dialog>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(withStyles(styles)(ContentStackModal))
