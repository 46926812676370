import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import StarHalf from '@material-ui/icons/StarHalf'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 15
  },
  stars: {
    color: '#757575'
  },
  label: {
    color: '#2b2b2b',
    fontSize: 16
  }
})

const AverageRatingStars = props => {
  const { classes } = props
  const average = Number(props.average)
  const ratings = [1, 2, 3, 4, 5]

  return (
    <div className={classes.root}>
      <div className={classes.stars}>
        {ratings.map(rating => {
          const averageAmount = average - rating
          if (average > rating || averageAmount === 0) {
            return <Star />
          } else if (averageAmount > 0 || averageAmount * -1 < 0.9) {
            return <StarHalf />
          } else {
            return <StarBorder />
          }
        })}
      </div>
      <span className={classes.label}>Avaliação média: {average}</span>
    </div>
  )
}

export default React.memo(withStyles(styles)(AverageRatingStars))
