import React, { useState } from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { searchMedicationActions, medicationListActions } from '../../../actions'
import SearchIcon from '@material-ui/icons/Search'
import RelativeWrapper from './RelativeWrapper'
import SwitchDesktopMobile from '../../../components/SwitchDesktopMobile'

const WrapperSearch = withStyles((theme) => ({
  wrapperSearch: {
    paddingRight: 8,
    paddingLeft: 8,
    background: 'transparent',
    position: 'relative',
    zIndex: 1205,
    '& > #searchbar-input-checkbox:checked ~ div header': {
      transform: 'translateY(0)',
    },
    '& > #searchbar-input-checkbox:checked ~ div .label-searchbar-open-button': {
      display: 'none',
      opacity: 0,
      height: 0,
    },
    '@media only print': {
      display: 'none !important',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    '@media only print': {
      display: 'none !important',
    },
  },
}))(({ classes, children, showSearchBar, ...rest }) => {
  return (
    <div {...rest} className={classNames(classes.wrapperSearch, 'hidden-print')}>
      <SwitchDesktopMobile desktop>
        {!!showSearchBar && <div className={classes.toolbar} />}
      </SwitchDesktopMobile>
      {children}
    </div>
  )
})

const SearchBarLabel = withStyles({
  label: {
    display: 'block',
    background: 'white',
    'box-shadow':
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    padding: '16px',
    color: '#a2a2a2',
    'border-radius': '4px',
    cursor: 'pointer',
    display: 'flex',
    'align-items': 'center',
    'white-space': 'nowrap',
    overflow: 'hidden',
    height: '48px',
    boxSizing: 'border-box',
    '@media only print': {
      display: 'none !important',
    },
  },
})(({ classes }) => {
  return (
    <label
      className={classNames(classes.label, 'label-searchbar-open-button', 'hidden-print')}
      htmlFor="searchbar-input-checkbox"
      style={{ marginTop: '16px' }}
    >
      <SearchIcon style={{ color: '#e3e3e3', marginRight: '13px' }} />
      Medicamento ou principio ativo
    </label>
  )
})

const SearchBarLayout = ({ searchTerm = '', changeTerm, showSearchBar, changeShowSearchBar }) => {
  const handleToggleSearch = (e) => {
    const value = e.target.checked
    changeShowSearchBar(value)
    const $medicationSearchBar = document.getElementById('js-medication-searchbar')
    if (value) {
      $medicationSearchBar && $medicationSearchBar.focus()
    } else {
      $medicationSearchBar && $medicationSearchBar.blur()
    }
  }
  return (
    <WrapperSearch showSearchBar={showSearchBar}>
      <input
        type="checkbox"
        style={{ display: 'none' }}
        id="searchbar-input-checkbox"
        className={'hidden-print'}
        checked={showSearchBar}
        onChange={handleToggleSearch}
      />
      <RelativeWrapper>
        <SearchBarLabel />
      </RelativeWrapper>
    </WrapperSearch>
  )
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.searchMedication.term,
    showSearchBar: state.medicationList.showSearchBar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTerm: (term) => {
      dispatch(searchMedicationActions.changeTerm(term))
    },
    changeShowSearchBar: (value) => {
      dispatch(medicationListActions.changeShowSearchBar(value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarLayout)
