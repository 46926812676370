import React from 'react'
import { TextBlock, RoundShape, RectShape } from 'react-placeholder/lib/placeholders'
import Divider from '@material-ui/core/Divider'

const List = (
  <div>
    <div
      style={{
        padding: '8px 0',
        borderTop: '1px solid #e8e8e8',
        borderBottom: '1px solid #e8e8e8',
        paddingLeft: 22,
        paddingRight: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <RectShape
        color="#d7d7d7"
        style={{
          width: 70,
          height: 10,
          flex: 'none',
          marginRight: 16,
          color: '#505152'
        }}
      />
      <div style={{ display: 'flex' }}>
        <RoundShape
          style={{ width: 90, height: 28, flex: 'none', marginLeft: 5 }}
          color="#d7d7d7"
        />
      </div>
    </div>
    {[1, 2, 3].map((item, index) => (
      <React.Fragment key={index}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 22px',
            height: 120,
            justifyContent: 'center'
          }}
        >
          <RoundShape
            style={{ width: 48, height: 48, flex: 'none', marginRight: 16 }}
            color="#d7d7d7"
          />
          <div style={{ flex: 1 }}>
            <TextBlock rows={2} color="#d7d7d7" style={{ marginTop: -15 }} />

            <TextBlock
              rows={4}
              color="#d7d7d7"
              style={{ height: 20, marginTop: 10, marginBottom: 10 }}
            />
          </div>
        </div>
        {index !== 2 && <Divider />}
      </React.Fragment>
    ))}
  </div>
)

export default List
