import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import { userInfoQueries } from '../graphql/queries'
import PenEmpty from '../icons/PenEmpty'
import PenFull from '../icons/PenFull'

const styles = theme => ({})

const NoteIconToolbar = ({ id, disabled }) => {
  return (
    <Query query={userInfoQueries.GET_USER_INFOS}>
      {({ loading, error, data: userInfos }) => {
        if (loading || error) return <PenEmpty />
        const hasNote = !!(userInfos.notes || []).find(({ conteudo }) => id === conteudo._id)
        return hasNote ? (
          <PenFull style={{ color: disabled ? 'rgba(255,255,255,.4)' : 'rgb(34, 159, 255)' }} />
        ) : (
          <PenEmpty />
        )
      }}
    </Query>
  )
}

export default React.memo(withStyles(styles, { withTheme: true })(NoteIconToolbar))
