import Loadable from 'react-loadable'

const asyncMessageDrawer = Loadable({
  loader: () => import('./MessagesDrawer'),
  loading: () => {
    return null
  }
})

export default asyncMessageDrawer
