import React from 'react'
import OnlyOnline from '../../components/OnlyOnline'
import SwitchDesktopMobile from '../../components/SwitchDesktopMobile'
import MedicationDesktop from './Medication.desktop'
import MedicationMobile from './Medication.mobile'

const Medication = props => {
  return (
    <OnlyOnline>
      <SwitchDesktopMobile
        mobileComponent={MedicationMobile}
        desktopComponent={MedicationDesktop}
      />
    </OnlyOnline>
  )
}

export default Medication
