import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import { metatagsQueries } from '../graphql/queries'
import ErrorHandling from '../components/ErrorHandling'
import OnlyOnline from '../components/OnlyOnline'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  root: {
    width: '100%',
    paddingBottom: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  header: {
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  wrapperPanels: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20
  },
  progressWrapper: {
    height: 'calc(100vh - 54px)',
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20
  }
})

const PrivacyPolicy = props => {
  const { classes } = props
  return (
    <OnlyOnline>
      <Query
        query={metatagsQueries.GET_META_TAG}
        variables={{ chave: 'politica_de_privacidade' }}
        fetchPolicy={'cache-and-network'}
      >
        {({ loading, error, data = {}, refetch, networkStatus }) => {
          const { metatag = {} } = data

          if (error) {
            return (
              <div className={classes.root}>
                <ErrorHandling error={error} refetch={refetch} networkStatus={networkStatus} />
              </div>
            )
          }

          if (loading) {
            return (
              <div className={classes.progressWrapper}>
                <CircularProgress className={classes.progress} size={30} />
              </div>
            )
          }

          return (
            <div className={classes.root}>
              <Typography className={classes.header} variant={'title'}>
                {'Política de privacidade'}
              </Typography>
              <div
                className={classes.container}
                dangerouslySetInnerHTML={{ __html: metatag.valor || '' }}
              />
            </div>
          )
        }}
      </Query>
    </OnlyOnline>
  )
}

export default withStyles(styles)(PrivacyPolicy)
