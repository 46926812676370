import React from 'react'
import classNames from 'classnames'
import Query from 'react-apollo/Query'
import { userInfoQueries } from '../../graphql/queries'
import { favoriteMutations } from '../../graphql/mutations'
import Mutation from 'react-apollo/Mutation'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import BookmarkBorder from '@material-ui/icons/BookmarkBorder'
import Bookmark from '@material-ui/icons/Bookmark'
import * as loggerClient from '../../services/loggerClient'

const styles = theme => ({
  menuButton: {
    margin: '0'
  }
})

const ToggleFavoriteButton = ({ classes, id, content = {}, disabled, ...rest }) => {
  return (
    <Query query={userInfoQueries.GET_USER_INFOS}>
      {({ loading, error, data }) => {
        if (loading || error || !content) {
          return (
            <IconButton
              color="#fff"
              {...rest}
              disabled={disabled}
              aria-label="Adiciona favorito"
              style={{ color: disabled ? 'rgba(255,255,255,.4)' : '#fff' }}
              className={classNames(classes.menuButton)}
            >
              <BookmarkBorder />
            </IconButton>
          )
        }
        const isFavorite = (data.favorites || []).find(({ _id }) => _id === id)

        if (!isFavorite) {
          return (
            <Mutation
              mutation={favoriteMutations.ADD_FAVORITE}
              update={(cache, { data }) => {
                if (!data.addFavorite) return
                const { favorites } = cache.readQuery({
                  query: userInfoQueries.GET_USER_INFOS
                })

                cache.writeQuery({
                  query: userInfoQueries.GET_USER_INFOS,
                  data: { favorites: favorites.concat([data.addFavorite]) }
                })
              }}
            >
              {(addFavorite, { data }) => {
                return (
                  <IconButton
                    color="inherit"
                    aria-label="Adiciona favorito"
                    style={{ color: disabled ? 'rgba(255,255,255,.4)' : '#fff' }}
                    disabled={disabled}
                    onClick={() => {
                      loggerClient.onInteract({
                        evento: 'inclusao',
                        contexto: 'favoritos',
                        conteudo: id,
                        tipo: content.tipo
                      })
                      addFavorite({
                        variables: {
                          content: id
                        },
                        optimisticResponse: {
                          __typename: 'Mutation',
                          addFavorite: {
                            _id: id,
                            __typename: 'Favorite',
                            titulo: content.titulo,
                            tipo: content.tipo,
                            principioAtivo: content.principioAtivo
                          }
                        }
                      })
                    }}
                    className={classNames(classes.menuButton)}
                  >
                    <BookmarkBorder />
                  </IconButton>
                )
              }}
            </Mutation>
          )
        } else {
          return (
            <Mutation
              mutation={favoriteMutations.REMOVE_FAVORITE}
              update={(cache, { data: removeFavorite }) => {
                if (!removeFavorite) return
                const { favorites } = cache.readQuery({
                  query: userInfoQueries.GET_USER_INFOS
                })

                cache.writeQuery({
                  query: userInfoQueries.GET_USER_INFOS,
                  data: {
                    favorites: favorites.filter(({ _id }) => _id !== id)
                  }
                })
              }}
            >
              {(removeFavorite, { data }) => {
                return (
                  <IconButton
                    color="inherit"
                    aria-label="Remove favorito"
                    onClick={() => {
                      loggerClient.onInteract({
                        evento: 'remocao',
                        contexto: 'favoritos',
                        conteudo: id,
                        tipo: content.tipo
                      })
                      removeFavorite({
                        variables: {
                          content: id
                        },
                        optimisticResponse: {
                          __typename: 'Mutation',
                          removeFavorite: true
                        }
                      })
                    }}
                    className={classNames(classes.menuButton)}
                  >
                    <Bookmark color="secondary" />
                  </IconButton>
                )
              }}
            </Mutation>
          )
        }
      }}
    </Query>
  )
}

export default React.memo(withStyles(styles, { withTheme: true })(ToggleFavoriteButton))
