import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import logoColor from '../icons/svg/logoColor.svg'
import ErrorHandling from '../components/ErrorHandling'

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  icon: {
    width: 200,
    marginBottom: 10
  }
})

const getSubscriptionError = ({ message }) => {
  const [isSubscriptionError, data] = /SubscriptionError: (.*)/.exec(message) || []

  if (isSubscriptionError && data) {
    try {
      return { subscriptionError: JSON.parse(data) }
    } catch (err) {
      return {}
    }
  }

  return {}
}

const ContentError = ({ error, classes, refetch, networkStatus }) => {
  const { subscriptionError = null } = getSubscriptionError(error)

  if (!subscriptionError) {
    return <ErrorHandling error={error} refetch={() => refetch()} networkStatus={networkStatus} />
  }

  return (
    <div className={classes.root}>
      <img
        src={logoColor}
        className={classes.icon}
        alt={'Falha ao carregar conteúdo'}
        title={'Falha ao carregar conteúdo'}
      />
      <Typography
        gutterBottom
        className={classes.title}
        style={{ fontSize: 16, textAlign: 'center' }}
      >
        {subscriptionError.message}
      </Typography>
      {subscriptionError.url && (
        <Button color="primary" href={subscriptionError.url} className={classes.button}>
          Verificar assinatura
        </Button>
      )}
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ContentError)
