export const CHANGE_TERM_MEDICATION = 'CHANGE_TERM_MEDICATION'
export const CHANGE_FILTER_MEDICATION = 'CHANGE_FILTER_MEDICATION'
export const RESET_FILTER_MEDICATION = 'RESET_FILTER_MEDICATION'

export const changeTerm = term => {
  return dispatch => {
    dispatch({ type: CHANGE_TERM_MEDICATION, term })
  }
}

export const changeFilter = filter => {
  return dispatch => {
    dispatch({ type: CHANGE_FILTER_MEDICATION, filter })
  }
}

export const resetFilter = () => {
  return dispatch => {
    dispatch({ type: RESET_FILTER_MEDICATION })
  }
}
