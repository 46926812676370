import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export const IconClose = props => (
  <SvgIcon {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={'currentColor'}
        d="M18.6,3.1l-1.7-1.7L10,8.3L3.1,1.4L1.4,3.1L8.3,10l-6.9,6.9l1.7,1.7l6.9-6.9l6.9,6.9l1.7-1.7
	L11.7,10L18.6,3.1z"
      />
    </g>
  </SvgIcon>
)

export default IconClose
