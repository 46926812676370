import React from 'react'
import OnlyOnline from '../../components/OnlyOnline'
import SwitchDesktopMobile from '../../components/SwitchDesktopMobile'
import PrescriptionDesktop from './PrescriptionDesktop'
import PrescriptionMobile from './PrescriptionMobile'

const Prescription = props => {
  return (
    <OnlyOnline>
      <SwitchDesktopMobile
        mobileComponent={PrescriptionMobile}
        desktopComponent={PrescriptionDesktop}
      />
    </OnlyOnline>
  )
}

export default Prescription
