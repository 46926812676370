import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

export const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paperAnchorRight: {
        height: '100%'
      }
    }
  },
  palette: {
    primary1Color: '#5d5c5c',
    primary2Color: '#5d5c5c',
    secondary1Color: '#229FFF',
    secondary2Color: '#229FFF',
    primary: {
      light: '#5d5c5c',
      main: '#5d5c5c',
      dark: '#5d5c5c',
      contrastText: '#fff'
    },
    secondary: {
      light: '#A7D4B9',
      main: '#6EBD8F',
      dark: '#19A97A',
      contrastText: '#fff'
    },
    pickerHeaderColor: '#5d5c5c'
  }
})

export default theme
