import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import { metatagsQueries } from '../graphql/queries'
import ErrorHandling from '../components/ErrorHandling'
import CircularProgress from '@material-ui/core/CircularProgress'
import OnlyOnline from '../components/OnlyOnline'
import RenderWalkthroughContent from '../components/RenderWalkthroughContent'

const styles = theme => ({
  root: {
    width: '100%'
  }
})

const Walkthrough = props => {
  const { classes } = props
  return (
    <OnlyOnline>
      <div className={classes.root}>
        <RenderWalkthroughContent />
      </div>
    </OnlyOnline>
  )
}

export default withStyles(styles)(Walkthrough)
