import { device } from './utils'

let started = false
let timeoutKey

const init = () => {
  started = true
  const $rootElement = document.getElementById('root')
  $rootElement && $rootElement.classList.add('--prevent-animation')
}

const fixGestureNavigationIOS = () => device.isIos() && init()

export const startAnimation = (time = 800) => {
  if (started) {
    const $rootElement = document.getElementById('root')
    $rootElement && $rootElement.classList.remove('--prevent-animation')

    if (timeoutKey) clearTimeout(timeoutKey)

    timeoutKey = setTimeout(() => {
      $rootElement && $rootElement.classList.add('--prevent-animation')
    }, time)
  }
}

export default fixGestureNavigationIOS
