import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Redirect from 'react-router-dom/Redirect'
import logoSvg from '../../icons/svg/logo.svg'
import ErrorHandling from '../../components/ErrorHandling'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import withRouter from 'react-router-dom/withRouter'
import * as API from '../../api'
import * as authService from '../../services/auth'
import queryString from 'query-string'

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    flex: 1,
    flexDirection: 'column',
    '@media only screen and (max-height: 420px)': {
      justifyContent: 'normal',
      overflow: 'auto'
    }
  },
  actionsWrapper: {
    '@media only screen and (max-height: 420px)': {
      paddingBottom: '40px !important'
    }
  },
  formWrapper: {
    '@media only screen and (max-height: 420px)': {
      paddingTop: '40px !important'
    }
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '85%',
    textAlign: 'center',
    maxWidth: 320
  },
  logo: {
    marginBottom: 20,
    maxWidth: '90%'
  },
  link: {
    color: '#fff',
    textAlign: 'center',
    display: 'block',
    opacity: 0.8,
    textDecoration: 'none',
    fontSize: 14,
    margin: '10px auto 10px'
  },
  createAccountWrapper: {
    color: 'rgba(255,255,255,.8)',
    fontSize: 13
  },
  createAccountDivider: {
    background: '#fff',
    height: 1,
    opacity: 0.2,
    marginTop: 20,
    marginBottom: 20,
    width: '100%'
  },
  createAccountLink: {
    textTransform: 'uppercase',
    color: '#fff',
    textDecoration: 'none',
    fontWeight: 'bold',
    margin: 8
  }
})

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      messageError: '',
      loading: false,
      error: null
    }
    this.loginInputRef = React.createRef()
  }

  componentDidMount() {
    const parsed = queryString.parseUrl(window.location.href)
    const { user, auth, cpf } = parsed.query

    authService.saveDirectAuthCredentials(window.location.search)

    if (window.parent === window) {
      this.props.history.push('/login')
    } else {
      this.login()
    }
  }

  setLoading = (loading, callback) => {
    if (typeof callback !== 'function') {
      this.setState({ loading: !!loading })
    } else {
      this.setState({ loading: !!loading }, callback)
    }
  }

  setLogged = logged => {
    this.setState({ logged })
  }

  login = () => {
    const parsed = queryString.parseUrl(window.location.href)
    const { user, auth, cpf } = parsed.query

    this.setLoading(true, () => {
      API.auth
        .directAuth({
          user,
          auth,
          cpf
        })
        .then(async response => {
          const { ok, status } = response
          if (ok) {
            response
              .json()
              .then(data => {
                const { token, refreshToken, ssoToken } = data
                authService.login({ token, refreshToken, ssoToken })
                this.setLoading(false)
                this.setLogged(true)
                this.clearError()
              })
              .catch(error => {
                this.setError(error)
                if (status === 401) {
                  this.setSnackbarMessage('Parâmetro(s) inválido(s)')
                } else {
                  this.setSnackbarMessage('Estamos com problemas, tente mais tarde.')
                }
                this.setLoading(false)
              })
          } else {
            this.setError(new Error(status))
            if (status === 401) {
              this.setSnackbarMessage(await response.text())
            } else {
              this.setSnackbarMessage('Estamos com problemas, tente mais tarde.')
            }
            this.setLoading(false)
          }
        })
        .catch(error => {
          this.setError(error)
          this.setSnackbarMessage('Estamos com problemas, tente mais tarde.')
          this.setLoading(false)
        })
    })
  }
  onOpenUpdatePassword = () => {
    this.setState({
      openUpdatePassword: true,
      openUpdatePasswordAlertDialog: false
    })
  }

  onOpenUpdatePasswordAlert = () => {
    this.setState({
      openUpdatePassword: false,
      openUpdatePasswordAlertDialog: true
    })
  }

  onCloseUpdatePassword = () => {
    this.setState({
      openUpdatePassword: false
    })
  }

  changeEmail = e => {
    this.setState({ email: e.target.value })
  }

  changePassword = e => {
    this.setState({ password: e.target.value })
  }

  clearErrorMessage = () => {
    this.setState({
      messageError: ''
    })
  }

  setSnackbarMessage = message => {
    this.setState({
      messageError: message
    })
  }

  setError = error => {
    this.setState({
      error
    })
  }

  clearError = () => {
    this.setState({
      error: null
    })
  }

  renderLoadingOrError = () => {
    const { classes } = this.props
    const { error, logged, loading, messageError } = this.state

    if (error && !loading) {
      return (
        <ErrorHandling error={error} errorMessage={messageError} refetch={() => this.login()} />
      )
    } else {
      return <CircularProgress color={'primary'} size={30} className={classes.buttonProgress} />
    }
  }

  render() {
    const { classes } = this.props
    const { messageError, logged, error } = this.state

    if (logged) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: {
              fromLogin: true
            }
          }}
        />
      )
    }

    return (
      <main className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!messageError}
          ContentProps={{
            'aria-describedby': 'login-error'
          }}
          message={<span id="login-error">{messageError}</span>}
        />
        <React.Fragment>{this.renderLoadingOrError()}</React.Fragment>
      </main>
    )
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Login))
