import React, { useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import SearchResultList from './SearchResultList'
import OnlyOnline from '../../components/OnlyOnline'
import SearchBarMedication from '../Medication/SearchBar'
import { connect } from 'react-redux'
import { searchMedicationActions } from '../../actions'
import ResultSearch from './SearchResultList/ResultSearch'
import PrescriptionPlaceholder from './PrescriptionPlaceholder'
import Content from '../Content'

const styles = (theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    flexDirection: 'column',
    height: 'calc(100% + 72px)',
    '@media only print': {
      display: 'none !important',
    },
  },
  searchBar: {
    '@media only print': {
      display: 'none !important',
    },
  },
})

const Medication = (props) => {
  const { classes, medicineLeaflet, searchTerm, showSearchBar } = props

  const [medicineLeafUpdated, setMedicineLeafUpdated] = useState()

  useEffect(() => {
    setMedicineLeafUpdated(medicineLeaflet)
  }, [])

  useEffect(() => {
    if (searchTerm === '') {
      setMedicineLeafUpdated(false)
    } else {
    }
  }, [searchTerm])

  useEffect(() => {
    setMedicineLeafUpdated(true)
  }, [medicineLeaflet])

  return (
    <OnlyOnline>
      <div className={classes.root}>
        <SearchBarMedication className={classes.searchBar} />
        {!showSearchBar ? <PrescriptionPlaceholder /> : <SearchResultList />}
      </div>
      {medicineLeaflet && searchTerm && medicineLeafUpdated ? <Content /> : null}
    </OnlyOnline>
  )
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.searchMedication.term,
    showSearchBar: state.medicationList.showSearchBar,
    medicineLeaflet: state.medicationList.medicineLeaflet,
    searchTerm: state.searchMedication.term,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTerm: (term) => {
      dispatch(searchMedicationActions.changeTerm(term))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Medication))
