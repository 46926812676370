import { device, safeLocalStorage } from '../../utils'

export const hideMessage = () => {
  if (!safeLocalStorage()) return false

  safeLocalStorage().setItem('showAddToHomeScreenIos', 1)

  return true
}

export const userHideMessage = () => {
  if (!safeLocalStorage()) return false
  return !!safeLocalStorage().getItem('showAddToHomeScreenIos')
}

const isCookieEnabled = () => 'cookieEnabled' in navigator && navigator.cookieEnabled

export const showAddToHomeScreen = () => {
  return (
    isCookieEnabled() && !!(device.isIos() && !device.isInStandaloneMode() && !userHideMessage())
  )
}

export default showAddToHomeScreen
