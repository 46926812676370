import React from 'react'
import SearchBar from 'material-ui-search-bar'
import IconClose from '@material-ui/icons/Close'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import { searchMedicationActions, medicationListActions } from '../../../actions'
import { connect } from 'react-redux'

const WrapperIconClose = withStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})(({ classes, children, ...rest }) => (
  <div {...rest} className={classes.root}>
    {children}
  </div>
))

const CustomSearchbar = withStyles({
  root: {
    height: '100%',
    marginLeft: 8,
    '& > div': {
      order: 1,
      width: '100% !important',
      marginLeft: '0 !important',
      '@media only print': {
        display: 'none !important',
      },
    },
  },
})(({ classes, searchTerm, runSearch, ...rest }) => (
  <SearchBar
    className={classes.root}
    placeholder=""
    id={'js-medication-searchbar'}
    value={searchTerm}
    closeIcon={
      <WrapperIconClose onClick={() => runSearch('')}>
        <IconClose style={{ color: 'rgb(158, 158, 158)' }} />
      </WrapperIconClose>
    }
    onChange={(newValue) => runSearch(newValue)}
  />
))

const ToolbarSearch = withStyles((theme) => ({
  root: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    zIndex: 9999,
    '& > div': {
      overflow: 'inherit',
    },
    '& > header': {
      display: 'block',
      transition: '.2s ease-in-out',
      transform: 'translateY(-100%)',
    },
    '@media only print': {
      display: 'none !important',
    },
  },
  toolbarOpen: {
    '& > header': {
      backgroundColor: '#5D5C5C',
      transform: 'translateY(0)',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px',
  },
  appBar: {
    backgroundColor: '#5D5C5C',
    zIndex: 999999,
    left: 0,
    top: 0,
    right: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbarSearchLeft: {
    flex: 1,
    width: '10px', // fix toolbar width on mobile
  },
  buttonOk: {
    color: '#fff',
    alignSelf: 'stretch',
  },
}))(
  ({ classes, children, changeTerm, searchTerm, showSearchBar, changeShowSearchBar, ...rest }) => {
    const getToolbarClassNames = () => {
      if (showSearchBar) {
        return classNames(classes.root, classes.toolbarOpen, 'hidden-print')
      } else {
        return classes.root
      }
    }

    return (
      <div {...rest} className={getToolbarClassNames()}>
        <AppBar className={classes.appBar} position={'absolute'}>
          <Toolbar disableGutters={true} className={classNames(classes.toolbar, 'container yAxisCleared')}>
            <div className={classes.toolbarSearchLeft}>
              <CustomSearchbar searchTerm={searchTerm} runSearch={changeTerm} />
            </div>
            <Button
              className={classes.buttonOk}
              component={'label'}
              htmlFor="searchbar-input-checkbox"
              onClick={() => changeTerm('') && changeShowSearchBar()}
            >
              Ok
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    searchTerm: state.searchMedication.term,
    showSearchBar: state.medicationList.showSearchBar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTerm: (term) => {
      dispatch(searchMedicationActions.changeTerm(term))
    },
    changeShowSearchBar: (value) => {
      dispatch(medicationListActions.changeShowSearchBar(value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarSearch)
