import escapeStringRegexp from 'escape-string-regexp'

const SYMBOL = escapeStringRegexp('•')

export const highlightHtml = (contentString, searchText) => {
  const normalizedSearch = escapeStringRegexp(normalizeString(searchText))

  if (normalizedSearch && normalizedSearch.length >= 2) {
    const { stripedContent, normalizedContent, tags } = prepareContent(contentString)
    const mountedSearch = mountSearch(normalizedSearch)

    const searchRegex = new RegExp(mountedSearch, 'igm')

    const markPositions = []
    const aditionalTags = []
    normalizedContent.replace(searchRegex, (match, ...args) => {
      let offset = null
      args.forEach(arg => {
        if (typeof arg === 'number') {
          offset = arg
        }
      })
      markPositions.push({ begin: offset, end: offset + match.length })
      match.replace(new RegExp(`(${SYMBOL})\\1*`, 'g'), (innerMatching, ...args) => {
        let aditionalOffset = null
        args.forEach(arg => {
          if (typeof arg === 'number') {
            aditionalOffset = arg
          }
        })

        aditionalTags.push({
          endTag: aditionalOffset,
          beginTag: aditionalOffset + innerMatching.length,
          matching: match
        })
      })
    })

    let highlight = stripedContent
    markPositions.forEach(({ begin, end }, index) => {
      const jump = 13 * index
      highlight =
        highlight.substring(0, begin + jump) +
        `<mark>${highlight.substring(begin + jump, end + jump)}</mark>` +
        highlight.substring(end + jump, highlight.length)
    })

    aditionalTags.forEach(({ endTag, beginTag, matching }) => {
      let originalPostion = {}

      normalizedContent.replace(escapeStringRegexp(matching), (match, offset) => {
        originalPostion = { begin: offset, end: offset + match.length }
      })

      const originalPhrase = stripedContent.substring(originalPostion.begin, originalPostion.end)

      highlight = highlight.replace(escapeStringRegexp(originalPhrase), match => {
        return (
          match.substring(0, endTag) +
          `</mark>${match.substring(endTag, beginTag)}<mark>` +
          match.substring(beginTag, match.length)
        )
      })
    })

    highlight = highlight.replace(new RegExp(`${SYMBOL}`, 'igm'), () => {
      return tags.shift()
    })

    return highlight.replace(/\n/gim, '')
  } else {
    return contentString
  }
}

const mountSearch = searchText => {
  let patternSearch = ''
  const regexPattern = `(\\s|${SYMBOL})*`
  const splitedSearch = searchText.split(' ') || []

  if (splitedSearch.length > 1) {
    splitedSearch.forEach((word, index) => {
      patternSearch += `${word}${index !== splitedSearch.length - 1 ? regexPattern : ''}`
    })
  } else {
    patternSearch = `${searchText}${regexPattern}`
  }

  return patternSearch
}

const prepareContent = htmlString => {
  const stripHtmlRegex = new RegExp('<[^>]*>', 'igm')
  const stripAnchorsRegex = /<a id="_idIndexMarker.*?<\/a>/gim
  const noAnchorContent = htmlString.replace(stripAnchorsRegex, '')
  const tags = noAnchorContent.match(stripHtmlRegex)
  const stripedContent = removeSpaceBetweenTags(
    removeSpaceBetweenTags(removeBreakSpaces(noAnchorContent.replace(stripHtmlRegex, SYMBOL)))
  )

  const normalizedContent = removeBreakSpaces(normalizeString(stripedContent))

  return { stripedContent, normalizedContent, tags }
}

const removeBreakSpaces = text => {
  return text.replace(/\s+/g, ' ').replace(/\r\n|\r|\n/g, '')
}

const removeSpaceBetweenTags = text => {
  return text.replace(new RegExp(`${SYMBOL}\s+${SYMBOL}`, 'g'), match => {
    return match.replace(/\s/g, '')
  })
}

const normalizeString = value => {
  let string = value
  var mapAccentsHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g
  }

  for (let letter in mapAccentsHex) {
    let accentRegex = mapAccentsHex[letter]
    string = string.replace(accentRegex, letter)
  }

  return string
}
