import * as messagesQueries from './messages'
import * as userQueries from './user'
import * as notificationQueries from './notification'
import * as faqQueries from './faq'
import * as menuQueries from './menu'
import * as metatagsQueries from './metatags'
import * as medicationQueries from './medication'
import * as informationInteractionQueries from './informationInteraction'
import * as shortMedicationInteractionQueries from './shortMedicationInteraction'
import * as sessionQueries from './session'
import * as specialtiesQueries from './specialties'
import * as prescriptionQueries from './prescription'
import * as contentQueries from './content'
import * as authorQueries from './author'
import * as noteQueries from './note'
import * as userInfoQueries from './userInfo'
import * as feedbackQueries from './feedback'

export {
  authorQueries,
  userInfoQueries,
  noteQueries,
  contentQueries,
  messagesQueries,
  metatagsQueries,
  userQueries,
  notificationQueries,
  faqQueries,
  menuQueries,
  medicationQueries,
  informationInteractionQueries,
  shortMedicationInteractionQueries,
  sessionQueries,
  specialtiesQueries,
  prescriptionQueries,
  feedbackQueries,
}
