import React, { useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 225,
    marginBottom: 10,
    margin: 'auto'
  },
  label: {
    position: 'absolute',
    bottom: -9,
    textAlign: 'center',
    fontSize: 12,
    color: '#949494'
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
})

const Rating = props => {
  const { classes, inputRef, onChangeValue } = props
  const [value, setValue] = useState(props.defaultValue || 0)
  const ratings = [1, 2, 3, 4, 5]

  useEffect(
    () => {
      onChangeValue(value)
    },
    [value]
  )

  return (
    <div className={classes.root}>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        value={value}
        name="rating"
        type={'number'}
      />
      {ratings.map(item => (
        <IconButton color={'primary'} onClick={() => setValue(item)}>
          {item <= value ? <Star /> : <StarBorder />}
        </IconButton>
      ))}
      <div className={classes.label}>Avalie este conteúdo</div>
    </div>
  )
}

export default React.memo(withStyles(styles)(Rating))
