import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '../icons/IconClose'
import BulaInteracaoIcon from '../icons/svg/BulaInteracaoIcon'
import MapIcon from '@material-ui/icons/Map'
import { medicationQueries } from '../graphql/queries'
import AuthorImage from './AuthorImage'

const styles = theme => ({
  paper: {
    maxWidth: 400,
    overflow: 'inherit',
    backgroundColor: theme.palette.background.paper
  },
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  content: {
    minHeight: 60,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  foto: {
    width: 59,
    height: 59,
    borderRadius: '50%'
  },
  nome: {
    color: '#19A97A',
    fontSize: 18,
    fontWeight: '400',
    margin: 0,
    textAlign: 'left',
    listStyle: 'none',
    textTransform: 'capitalize',
    marginTop: 12,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  especializacao: {
    color: '#6484b0',
    fontSize: 16
  },
  header: {
    paddingTop: 24,
    padding: 0,
    textAlign: 'left',
    borderBottom: '1px solid #CDCDCD',
    paddingBottom: 20
  },
  optionName: {
    marginTop: 12
  },
  curriculo: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: '1em',
    overflow: 'auto',
    '@media only screen and (orientation: landscape)': {
      overflow: 'initial'
    }
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    backgroundColor: '#6EBD8F',
    '&, &:hover, &:focus': {
      background: '#19A97A',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  }
})

class PrescriptionsDialog extends React.Component {
  constructor(props) {
    super(props)
  }

  handleCancel = () => {
    this.props.onClose()
  }

  renderSpinner = () => {
    const { classes } = this.props
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} size={30} />
      </div>
    )
  }

  render() {
    const { value, searchOrder, classes, content, handleActiveContent, ...other } = this.props

    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        className={classes.root}
        {...other}
      >
        <IconButton className={classes.closeButton} onClick={this.handleCancel} aria-label="Sair">
          <IconClose style={{ fontSize: 20 }} viewBox={'0 0 20 20'} />
        </IconButton>
          <React.Fragment>
            <DialogContent className={classes.content}>
              <DialogTitle className={classes.header}>
                Bulas
              </DialogTitle>
              <ul style={{ padding: 0 }}>
              {(value || []).map(item => {
                return <li
                  id={'confirmation-dialog-title'}
                  className={classes.nome}
                  onClick={() => handleActiveContent(item._id, item.principioAtivo)}
                >
                  <BulaInteracaoIcon />
                  {item.principioAtivo.toLowerCase()}
                </li>
              })}
              </ul>
            </DialogContent>
          </React.Fragment>
      </Dialog>
    )
  }
}

export default withStyles(styles)(PrescriptionsDialog)
