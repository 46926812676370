import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import SearchResultList from './SearchResultList'
import OnlyOnline from '../../components/OnlyOnline'
import SearchBarMedication from './SearchBar'
import { connect } from 'react-redux'
import { searchMedicationActions } from '../../actions'
import ResultSearch from './SearchResultList/ResultSearch'

const styles = theme => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    flexDirection: 'column',
    height: '100%'
  }
})

const Medication = props => {
  const { classes, searchTerm, showSearchBar } = props
  return (
    <OnlyOnline>
      <div className={classes.root}>
        <SearchBarMedication />
        {!showSearchBar ? <ResultSearch /> : <SearchResultList />}
      </div>
    </OnlyOnline>
  )
}

const mapStateToProps = state => {
  return {
    searchTerm: state.searchMedication.term,
    showSearchBar: state.medicationList.showSearchBar
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeTerm: term => {
      dispatch(searchMedicationActions.changeTerm(term))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Medication))
