import gql from 'graphql-tag'

export const UPDATE_NOTE = gql`
  mutation updateNote($content: String!, $note: String!) {
    updateNote(content: $content, note: $note) {
      conteudo {
        _id
        titulo
        tipo
        principioAtivo
      }
      anotacao
    }
  }
`
