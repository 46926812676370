import gql from 'graphql-tag'

export const GET_FEEDBACK = gql`
  query feedback($conteudo: String!) {
    feedback(conteudo: $conteudo) {
      nota
      comentario
    }

    metatag(chave: "mensagem_avaliacao") {
      valor
    }
  }
`
