export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'

export const showSnackbar = message => {
  return dispatch => {
    dispatch({ type: SHOW_SNACKBAR, message })
  }
}

export const hideSnackbar = () => {
  return dispatch => {
    dispatch({ type: HIDE_SNACKBAR })
  }
}
