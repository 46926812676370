import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { persistStore } from 'redux-persist'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import reducer from '../reducers'

export const history = createBrowserHistory()

const store = createStore(
  reducer(history),
  compose(applyMiddleware(routerMiddleware(history), thunk))
)

export const persistor = persistStore(store)

export default store
