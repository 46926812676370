import gql from 'graphql-tag'

export const REMOVE_MESSAGE = gql`
  mutation removeMessage($id: String!) {
    removeMessage(id: $id)
  }
`

export const MARK_AS_READ = gql`
  mutation markMessageAsRead($id: String!) {
    markMessageAsRead(id: $id)
  }
`
