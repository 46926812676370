import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
// import { getMainDefinition } from 'apollo-utilities' // SESSION_CONTROL
import { setContext } from 'apollo-link-context'
import linkError from './linkError'
import linkRefresh from './linkRefresh'
import * as authService from '../services/auth'
// import { split } from 'apollo-link' // SESSION_CONTROL
// import linkWS from './linkWS' // SESSION_CONTROL

const { REACT_APP_API_BASE_URL, REACT_APP_VERSION } = process.env

const httpLink = createHttpLink({
  uri: `${REACT_APP_API_BASE_URL}/graphql`
})

const authLink = setContext((_, { headers }) => {
  const ssoToken = authService.getSSOToken()
  const token = authService.getAccessToken()
  const authHeaders = {}

  if (token && ssoToken) {
    authHeaders['x-ssotoken'] = ssoToken
    authHeaders['Authorization'] = token
  }

  return {
    headers: {
      ...headers,
      ...authHeaders,
      'x-client-version': REACT_APP_VERSION,
      'x-parent-referrer': document.referrer
    }
  }
})

let apolloHTTPClient = () => {
  return linkError()
    .concat(linkRefresh())
    .concat(authLink)
    .concat(httpLink)
}

// let client = new ApolloClient({
//   // SESSION_CONTROL
//   link: split(
//     ({ query }) => {
//       const { kind, operation } = getMainDefinition(query)
//       return kind === 'OperationDefinition' && operation === 'subscription'
//     },
//     linkWS,
//     apolloHTTPClient()
//   ),
//   cache: new InMemoryCache()
// })

let client = new ApolloClient({
  link: apolloHTTPClient(),
  cache: new InMemoryCache()
})

export default client
