import withRouter from 'react-router-dom/withRouter'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '../icons/IconClose'
import { notificationQueries } from '../graphql/queries'
import * as authService from '../services/auth'
import { safeLocalStorage } from '../utils'

const styles = theme => ({
  paper: {
    maxWidth: 400,
    overflow: 'inherit',
    backgroundColor: theme.palette.background.paper
  },
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  content: {
    minHeight: 60,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  foto: {
    width: 59,
    height: 59,
    borderRadius: '50%'
  },
  nome: {
    color: '#797979',
    fontSize: 24,
    fontWeight: '400',
    margin: 0
  },
  especializacao: {
    color: '#6484b0',
    fontSize: 16
  },
  header: {
    padding: '24px 0 8px',
    textAlign: 'center',
    '& > *': {
      color: '#0080E2',
      fontSize: 24
    }
  },
  headerName: {
    marginTop: 20
  },
  message: {
    fontSize: 20,
    fontWeight: 400,
    marginTop: '1em',
    overflow: 'auto',
    '@media only screen and (orientation: landscape)': {
      overflow: 'initial'
    }
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  }
})

class NotificationDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleCancel = () => {
    this.setState({
      open: false
    })
    this.addCheckInterval()
  }

  onOpenDialog = () => {
    this.setState({
      open: true
    })
  }

  changeNote = anotacao => {
    this.setState({ anotacao })
  }

  handleEntering = () => {
    safeLocalStorage().setItem('notification', Date.now())
    this.clearCheckInterval()
  }

  checkNewDate = () => {
    const lastNotification = safeLocalStorage().getItem('notification')

    if (!lastNotification || !authService.getSSOToken()) {
      return this.onOpenDialog()
    }

    const now = Date.now()
    const notificationTime = now - lastNotification
    if (notificationTime >= 3.6e6) {
      this.onOpenDialog()
    }
  }

  componentDidMount() {
    this.addCheckInterval()
  }

  componentWillUnmount() {
    this.clearCheckInterval()
  }

  addCheckInterval() {
    window.checkNotificationInterval = setInterval(() => {
      this.checkNewDate()
    }, 10000)
  }

  clearCheckInterval = () => {
    window.clearInterval(window.checkNotificationInterval)
  }

  render() {
    const { value, searchOrder, classes, content, ...other } = this.props
    const { open } = this.state

    if (!authService.getAccessToken()) {
      return null
    }

    return (
      <Query query={notificationQueries.GET_NOTIFICATION} fetchPolicy={'cache-and-network'}>
        {({ loading, error, data = {} }) => {
          if (loading || error || !data || !data.notification) {
            return null
          }

          const { notification = {} } = data
          return (
            <Dialog
              maxWidth="xs"
              fullWidth
              aria-labelledby="confirmation-dialog-title"
              className={classes.root}
              open={open}
              onEntered={this.handleEntering}
              {...other}
            >
              <IconButton
                className={classes.closeButton}
                onClick={this.handleCancel}
                aria-label="Sair"
              >
                <IconClose style={{ fontSize: 20 }} viewBox={'0 0 20 20'} />
              </IconButton>
              <React.Fragment>
                <DialogContent className={classes.content}>
                  <DialogTitle className={classes.header}>
                    <div dangerouslySetInnerHTML={{ __html: notification.title || '' }} />
                  </DialogTitle>
                  <div className={classes.message}>
                    <div dangerouslySetInnerHTML={{ __html: notification.message || '' }} />
                  </div>
                </DialogContent>
              </React.Fragment>
            </Dialog>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(withStyles(styles)(NotificationDialog))
