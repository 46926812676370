import React from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Search from '@material-ui/icons/Search'
import Close from '@material-ui/icons/Close'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'

const styles = () => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  menuButton: {
    margin: '0'
  }
})

const TextFieldSearch = withStyles({
  root: {
    '& > *, &:hover > *, &:focus > *': {
      color: '#fff'
    },
    '& > div': {
      '&::before': {
        borderBottom: '1px solid #fff !important'
      },
      '&::after': {
        borderBottom: '1px solid #fff !important'
      }
    }
  }
})(TextField)

class SearchboxHighlight extends React.Component {
  state = {
    searching: false
  }

  toggleSearch = () => {
    const { searching } = this.state
    const { onStateChange } = this.props

    this.setState({ searching: !searching }, () => {
      if (onStateChange) onStateChange()
    })
  }

  render() {
    const { toggleSearch } = this
    const { searching } = this.state
    const { children, value, onChange, disabled, classes, tipo } = this.props

    return (
      <div className={classes.wrapper}>
        {searching ? (
          <TextFieldSearch
            id="search-highlight"
            value={value}
            onChange={onChange}
            fullWidth
            autoFocus
            InputProps={{
              ref: this.loginInputRef,
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    color="inherit"
                    aria-label="Pesquisar"
                    onClick={() => {
                      toggleSearch()
                    }}
                    disabled={disabled}
                  >
                    <Close style={{ fontSize: 14 }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        ) : (
          <>
            {tipo === 'cid10' || tipo === 'cid11' ? null : (
              <IconButton
                color="inherit"
                aria-label="Pesquisar"
                onClick={() => {
                  toggleSearch()
                }}
                className={classNames(classes.menuButton)}
                disabled={disabled}
              >
                <Search />
              </IconButton>
            )}
            {children}
          </>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(SearchboxHighlight)
