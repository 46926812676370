import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export const FontIncrease = props => (
  <SvgIcon {...props}>
    <path
      fill={'inherit'}
      d="M16.2,16.8H7.9L6,22H3.2l7.6-20h2.3l7.7,20h-2.7L16.2,16.8z M8.6,14.7h6.8L12,5.3L8.6,14.7z
	"
    />
  </SvgIcon>
)

export default FontIncrease
