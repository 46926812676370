import Loadable from 'react-loadable'

const asyncAppMenu = Loadable({
  loader: () => import('./AppMenu'),
  loading: () => {
    return null
  }
})

export default asyncAppMenu
