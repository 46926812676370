import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CircularProgress from '@material-ui/core/CircularProgress'
import withRouter from 'react-router-dom/withRouter'
import Query from 'react-apollo/Query'
import { userQueries, specialtiesQueries } from '../graphql/queries'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Account from '@material-ui/icons/Person'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import EmailIcon from '@material-ui/icons/Email'
import EditIcon from '@material-ui/icons/Edit'
import LockIcon from '@material-ui/icons/Lock'
import EditUserDialog from '../components/EditUserDialog'
import Redirect from 'react-router-dom/Redirect'
import * as authService from '../services/auth'
import Snackbar from '@material-ui/core/Snackbar'
import UpdatePasswordDialog from '../components/UpdatePasswordDialog'
import OnlyOnline from '../components/OnlyOnline'
import { startAnimation } from '../fixGestureNavigationIOS'
import WcIcon from '@material-ui/icons/Wc'
import Specialty from '@material-ui/icons/School'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    background: '#f7f7f7'
  },
  appBar: {
    backgroundColor: '#5d5c5c',
    zIndex: theme.zIndex.drawer + 1
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  toolBar: {
    padding: '0 8px'
  },
  menuLink: {
    display: 'flex',
    textDecoration: 'none',
    padding: '12px 16px',
    '& > svg': {
      color: 'rgba(0, 0, 0, 0.54) !important'
    },
    '&.menu-link-active': {
      background: '#eee',
      '& > div span': {
        color: '#0080E2'
      },
      '& > svg': {
        color: '#0080E2 !important'
      }
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  menuButton: {
    margin: '0'
  },
  toolbarUser: {
    color: 'inherit',
    fontSize: '.9rem',
    display: 'block'
  },
  toolbarMail: {
    color: 'inherit',
    fontSize: '.7rem'
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px'
  },
  childrenWrapper: {
    width: '100%',
    height: 'calc(100% - 55px)',
    '-webkit-overflow-scrolling': 'touch' /* Lets it scroll lazy */,
    overflow: 'auto',
    background: '#fff',
    '@media only screen and (max-width: 900px) and (orientation: landscape)': {
      height: 'calc(100% - 47px)'
    }
  },
  singleMessage: {
    display: 'block',
    maxWidth: '250px',
    whiteSpace: 'normal',
    '& > span': {
      color: '#000',
      fontSize: '16px',
      fontWeight: '400'
    },
    '& > div': {
      color: '#000',
      fontSize: '14px',
      fontWeight: '400'
    },
    '& > div span': {
      color: '#9e9e9e',
      lineHeight: '1.4',
      fontWeight: '400'
    }
  },
  userHeader: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  itemText: {
    '& > *': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  lockIcon: {
    marginRight: 5,
    fontSize: 18
  }
})

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.contentWrapperRef = React.createRef()
    this.state = {
      openUpdatePassword: false,
      openEdit: false,
      snackMessage: ''
    }
  }

  onOpenUpdatePassword = () => {
    this.setState({
      openUpdatePassword: true,
      openEdit: false
    })
  }

  onOpenEdit = () => {
    this.setState({
      openEdit: true,
      openUpdatePassword: false
    })
  }

  onCloseUpdatePassword = () => {
    this.setState({
      openUpdatePassword: false
    })
  }

  onCloseEdit = () => {
    this.setState({
      openEdit: false
    })
  }

  renderSpinner = () => {
    const { classes } = this.props
    return <CircularProgress className={classes.progress} size={30} />
  }

  unabbreviatedGender = name => {
    switch (name) {
      case 'M':
        return 'Masulino'
      case 'F':
        return 'Feminino'
      default:
        return 'Outros'
    }
  }

  renderUser = user => {
    const { classes } = this.props
    const { unabbreviatedGender } = this
    return (
      <div className={classes.container}>
        <List>
          <ListItem>
            <Avatar>
              <Account />
            </Avatar>
            <ListItemText
              className={classes.itemText}
              primary="Nome"
              secondary={`${user.name} ${user.lastname}`}
            />
          </ListItem>
          <ListItem>
            <Avatar>
              <EmailIcon />
            </Avatar>
            <ListItemText className={classes.itemText} primary="E-mail" secondary={user.email} />
          </ListItem>
          <ListItem>
            <Avatar>
              <WcIcon />
            </Avatar>
            <ListItemText
              className={classes.itemText}
              primary="Gênero"
              secondary={unabbreviatedGender(user.gender)}
            />
          </ListItem>

          {this.renderSpecialties(user)}
        </List>

        <Divider style={{ margin: '24px 0 12px' }} />
        <div>
          <Button
            color="primary"
            onClick={this.onOpenUpdatePassword}
            className={classes.buttonLock}
          >
            <LockIcon className={classes.lockIcon} /> Alterar senha
          </Button>
        </div>
      </div>
    )
  }

  renderSpecialties = user => {
    return (
      <Query query={specialtiesQueries.GET_SPECIALTIES} fetchPolicy={'cache-and-network'}>
        {({ loading, error, data = {} }) => {
          const { specialties = [] } = data
          const { classes } = this.props
          let specialtiesNames = []
          if (specialties.length) {
            for (const specialty of (user || {}).speciality || []) {
              specialtiesNames.push(
                (specialties.filter(item => item.value === specialty)[0] || []).label
              )
            }
          }

          return (
            <ListItem>
              <Avatar>
                <Specialty />
              </Avatar>
              <ListItemText
                className={classes.itemText}
                primary="Especialidades"
                secondary={specialtiesNames.join(', ')}
              />
            </ListItem>
          )
        }}
      </Query>
    )
  }

  openSnackMessage = message => {
    this.setState({
      snackMessage: message
    })
  }

  closeSnackMessage = () => {
    this.setState({
      snackMessage: ''
    })
  }

  render() {
    const { classes } = this.props
    if (authService.isDirectToken()) {
      return <Redirect to={'/'} />
    }
    return (
      <Query query={userQueries.GET_USER} fetchPolicy={'cache-and-network'}>
        {({ loading, error, data = {} }) => {
          const { user = {} } = data

          return (
            <div className={classNames(classes.root, 'transition-item detail-page')}>
              <AppBar position="absolute" className={classNames(classes.appBar)}>
                <Toolbar disableGutters={true} className={classNames(classes.toolBar, 'container')}>
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    onClick={() => {
                      startAnimation()
                      this.props.history.goBack()
                    }}
                    className={classNames(classes.menuButton)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant="title" color="inherit" noWrap>
                    Meu perfil
                  </Typography>
                  <div className={classes.toolBarRight}>
                    <Button
                      color="inherit"
                      style={{ color: loading || error ? 'rgba(255,255,255,.4)' : '#fff' }}
                      aria-label="Editar"
                      className={classNames(classes.menuButton)}
                      disabled={loading || error}
                      onClick={() => {
                        this.onOpenEdit()
                      }}
                    >
                      <EditIcon style={{ fontSize: 18, marginRight: 5 }} /> Editar
                    </Button>
                  </div>
                </Toolbar>
              </AppBar>
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <OnlyOnline>
                  <div className={classNames(classes.childrenWrapper)}>
                    <div className={'container'}>
                      {loading ? this.renderSpinner() : this.renderUser(user)}
                    </div>
                  </div>
                </OnlyOnline>
              </main>
              <EditUserDialog
                open={this.state.openEdit}
                user={user}
                onSuccess={message => {
                  this.openSnackMessage(message)
                  this.onCloseEdit()
                }}
                onClose={() => {
                  this.onCloseEdit()
                }}
              />

              <UpdatePasswordDialog
                open={this.state.openUpdatePassword}
                email={user.email}
                onSuccess={message => {
                  this.openSnackMessage(message)
                  this.onCloseUpdatePassword()
                }}
                onClose={() => {
                  this.onCloseUpdatePassword()
                }}
              />
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3500}
                onClose={() => {
                  this.closeSnackMessage()
                }}
                open={!!this.state.snackMessage}
                ContentProps={{
                  'aria-describedby': 'login-error'
                }}
                message={<span id="login-error">{this.state.snackMessage}</span>}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Profile))
