export const OPEN_MENU_DRAWER = 'OPEN_MENU_DRAWER'
export const CLOSE_MENU_DRAWER = 'CLOSE_MENU_DRAWER'

export const openMenuDrawer = () => {
  return dispatch => {
    dispatch({ type: OPEN_MENU_DRAWER })
  }
}

export const closeMenuDrawer = () => {
  return dispatch => {
    dispatch({ type: CLOSE_MENU_DRAWER })
  }
}
