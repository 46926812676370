import React from 'react'
import Route from 'react-router-dom/Route'
import Redirect from 'react-router-dom/Redirect'
import * as authService from '../services/auth'
import DirectAuthPrivacyError from '../components/DirectAuthPrivacyError'
import { isInWhitelist } from '../utils'

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (document.referrer && !isInWhitelist(document.referrer)) {
    return <DirectAuthPrivacyError />
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (!authService.getAccessToken()) {
          if (authService.isDirectAuth()) {
            const TOKENS_AUTH = authService.getDirectAuthCredentials()
            if (TOKENS_AUTH) {
              return <Redirect to={`/auth${TOKENS_AUTH}`} />
            }
          }
          return <Redirect to={'/login'} />
        }
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
