import gql from 'graphql-tag'

export const SEARCH_SHORT_INTERACTION = gql`
  query shortMedicationsInteractions($term: [String]!) {
    shortMedicationsInteractions(term: $term) {
      _id
      medicamentos {
        nome
        conteudos {
          _id
          principioAtivo
        }
      }
      nome
      gravidade
      gravidadeOrdem
      corGravidade
      corGravidadeMenor
      corGravidadeModerada
      corGravidadeMaior
    }
  }
`