import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import OnlyOnline from '../../components/OnlyOnline'
import { connect } from 'react-redux'
import withApollo from 'react-apollo/withApollo'
import Button from '@material-ui/core/Button'
import { safeLocalStorage } from '../../utils'
import Redirect from 'react-router-dom/Redirect'
import Medication from '../Medication'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    paddingBottom: 15
  },
  title: {
    color: '#505152',
    padding: 8,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media only screen and (max-width: 480px)': {
      fontSize: '1em'
    }
  },
  markSearch: {
    color: '#fff',
    fontSize: 13,
    flexShrink: 0,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 5,
    minHeight: 28,
    background: '#c50000',
    marginLeft: 'auto',
    '&:hover, &:focus': {
      background: '#c50000'
    }
  }
})

class Home extends Component {
  constructor(props) {
    super(props)
    this.client = null
  }

  render() {
    const { classes } = this.props

    // const showWalkthrough = safeLocalStorage() && safeLocalStorage().getItem('showWalkthrough')

    // if (!showWalkthrough) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: '/tutorial',
    //         state: {
    //           fromLogin: true
    //         }
    //       }}
    //     />
    //   )
    // }

    return <Medication />
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withApollo(Home)))
