import InfiniteScroll from 'react-infinite-scroller'

export default class SearchResultListScroll extends InfiniteScroll {
  getParentElement(el) {
    const parentElementId = (this.props && this.props.parentElementId) || 'infinite-scroll-wrapper'
    return document.getElementById(parentElementId)
  }

  render() {
    return super.render()
  }
}
