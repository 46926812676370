import React from 'react'
import Walkthrough from 'mb-react-walkthrough'
import 'mb-react-walkthrough/src/css/mb-react-walkthrough.scss'
import withStyles from '@material-ui/core/styles/withStyles'
import imgSlide1 from '../images/WalkThru-1.png'
import imgSlide2 from '../images/WalkThru-2.png'
import imgSlide3 from '../images/WalkThru-3.png'
import imgSlide4 from '../images/WalkThru-4.png'
import imgSlide5 from '../images/WalkThru-5.png'
import Redirect from 'react-router-dom/Redirect'
import { safeLocalStorage } from '../utils'
import IconClose from '../icons/IconClose'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  root: {
    width: '100%'
  },
  titulo: {
    marginTop: 50,
    color: '#333',
    fontWeight: 'bold',
    fontSize: 32
  },
  descricao: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    color: '#333',
    textAlign: 'center',
    fontSize: 18
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 'calc(80.5%)',
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  }
})

const closeWalkthrough = () => {
  safeLocalStorage() && safeLocalStorage().setItem('showWalkthrough', true)
}

class RenderWalkthroughContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      show: true
    }
  }

  handleCancel = () => {
    safeLocalStorage() && safeLocalStorage().setItem('showWalkthrough', true)
    this.setState({ show: false })
  }

  render() {
    const { classes } = this.props

    if (!this.state.show) return <Redirect to={'/'} />

    return (
      <div className={classes.root}>
        <Walkthrough
          nextBtnTitle={'Próximo'}
          backBtnTitle={'Voltar'}
          closeBtnTitle={'Concluir'}
          width={800}
          height={680}
          onHide={() => (this.setState({ show: false }), closeWalkthrough())}
        >
          <Walkthrough.Content>
            <IconButton
              className={classes.closeButton}
              onClick={this.handleCancel}
              aria-label="Sair"
            >
              <IconClose style={{ fontSize: 20 }} viewBox={'0 0 20 20'} />
            </IconButton>
            <div className={classes.titulo}>Curadores</div>
            <div>
              <img src={imgSlide1} style={{ width: '400px', height: '400px' }} />
            </div>
            <div className={classes.descricao}>No menu lateral, você nossa lista de curadores</div>
          </Walkthrough.Content>
          <Walkthrough.Content>
            <div className={classes.titulo}>Filtros</div>
            <div>
              <img src={imgSlide2} style={{ width: '400px', height: '400px' }} />
            </div>
            <div className={classes.descricao}>
              Ao fazer sua busca, clique no botão OPÇÕES, para ordenar ou filtrar sua pesquisa
            </div>
          </Walkthrough.Content>
          <Walkthrough.Content>
            <div className={classes.titulo}>Parâmetros</div>
            <div>
              <img src={imgSlide3} style={{ width: '400px', height: '400px' }} />
            </div>
            <div className={classes.descricao}>
              Filtre sua pesquisa de acordo com o conteúdo que deseja ver, além de ordenar por
              relevância, data de publicação e nomes dos autores
            </div>
          </Walkthrough.Content>
          <Walkthrough.Content>
            <div className={classes.titulo}>Conteúdos</div>
            <div>
              <img src={imgSlide4} style={{ width: '400px', height: '400px' }} />
            </div>
            <div className={classes.descricao}>
              Dentro do conteúdo, faça suas anotações, aumente ou diminua a fonte e favorite (canto
              superior direito)
            </div>
          </Walkthrough.Content>
          <Walkthrough.Content>
            <div className={classes.titulo}>Informações</div>
            <div>
              <img src={imgSlide5} style={{ width: '400px', height: '400px' }} />
            </div>
            <div className={classes.descricao}>
              No ícone de informações, você pode ver quando o conteúdo foi atualizado e o nome dos
              autores e editores
            </div>
          </Walkthrough.Content>
        </Walkthrough>
      </div>
    )
  }
}

export default withStyles(styles)(RenderWalkthroughContent)
