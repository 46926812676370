import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export const Drug = props => (
  <SvgIcon {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Drug" fill="currentcolor" fillRule="nonzero">
        <g id="baseline-spellcheck-24px">
          <path
            d="M12.65,14.37c-.09-.23-.17-.45-.26-.67a5.08,5.08,0,0,1-.26-.71c0-.18-.05-.44.06-.54a.62.62,0,0,1,.54,0c1.28.5,2.55,1,3.83,1.54l2.07.84a.91.91,0,0,1,.52,1.4c-.94,1.85-1.88,3.69-2.84,5.52a.79.79,0,0,1-.45.38c-.28.05-.36-.22-.44-.43-.14-.37-.27-.74-.42-1.1a1.17,1.17,0,0,0-1.43-.69,8.88,8.88,0,0,1-7.81-1.65,10,10,0,0,1-1.23-1.12c-.11-.11-.08-.35-.12-.53.18,0,.39-.14.54-.09a8.59,8.59,0,0,0,5.14.06c.61-.16,1.22-.34,1.81-.55A1.11,1.11,0,0,0,12.65,14.37Zm-7.59.73a.8.8,0,0,0,.81-.8v-.63h.65a.8.8,0,1,0,0-1.59H5.87v-.63a.81.81,0,0,0-1.62,0v.63H3.59a.8.8,0,1,0,0,1.59h.65v.63A.81.81,0,0,0,5.06,15.1ZM9.23,9.43V8.08H6a.8.8,0,0,0-.82.79.81.81,0,0,0,.82.8H8.4v1.06A3.44,3.44,0,0,1,9.23,9.43ZM2.92,19.62a.82.82,0,0,1-.83-.81V17.6H3.68a1.63,1.63,0,0,1-.23-.77.2.2,0,0,1,0-.07A1,1,0,0,1,3.59,16H2.09l0-6.37H3.47a.79.79,0,1,0,0-1.58H2.09a.85.85,0,0,1,.83-.78H9.23V5.85s0-.1,0-.15H3.13V2.09a.49.49,0,0,1,.48-.48h8.05a.5.5,0,0,1,.49.48v.7h1.64V2.09A2.11,2.11,0,0,0,11.66,0h-8A2.12,2.12,0,0,0,1.5,2.09v4a2.39,2.39,0,0,0-1,2V18.83a2.43,2.43,0,0,0,2.46,2.4H8.2v-.6a11.08,11.08,0,0,1-2.12-1Zm20.62-7.75V22.6A2.43,2.43,0,0,1,21.08,25H11.65a2.43,2.43,0,0,1-2.46-2.4V20.91a9.83,9.83,0,0,0,1.63.22v1.45a.82.82,0,0,0,.83.81h9.43a.82.82,0,0,0,.83-.81V11.87a.83.83,0,0,0-.83-.82H11.65a.84.84,0,0,0-.83.78v3.44c-.33.11-.64.21-1,.3s-.43.09-.65.13V11.87a2.39,2.39,0,0,1,1-2V5.85a2.11,2.11,0,0,1,2.13-2.08h8a2.12,2.12,0,0,1,2.13,2.08V9.92A2.38,2.38,0,0,1,23.54,11.87Zm-2.67-6a.48.48,0,0,0-.48-.47h-8a.49.49,0,0,0-.49.47V9.46h9Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Drug
