import gql from 'graphql-tag'

export const SEARCH_CONTENT = gql`
  query contents(
    $term: String!
    $offset: Int
    $order: String
    $filter: String
    $range: String
    $specialty: SpecialtyEnum
    $readingTimes: String
  ) {
    contents(
      term: $term
      offset: $offset
      order: $order
      filter: $filter
      range: $range
      specialty: $specialty
      readingTimes: $readingTimes
    ) {
      total
      items {
        id
        tipo
        titulo
        autores
        autoresDiretriz
        highlight
        descricao
        tituloCapitulo
        tituloLivro
        score
        copyright
        codigo
        minutosLeituraClassificacao
        minutosLeituraClassificacaoTitulo
        minutosLeitura
      }
    }
  }
`

export const GET_CONTENT = gql`
  query content($id: String!) {
    content(_id: $id) {
      tipo
      titulo
      principioAtivo
      instituicao
      autoresDiretriz
      conteudoHtml
      descricao
      url
      tituloCurso
      codigo
      deletedAt
      detalhesAula
      avaliacaoMedia
      autores {
        _id
        nome
      }
      codigo
      origemId
      tituloCapitulo
      tituloDeT
      tituloArtigo
      tipoOrigem
      minutosLeituraClassificacaoTitulo
      minutosLeitura
    }

    metatag(chave: "disclaimer") {
      valor
    }
  }
`

export const GET_CONTENT_TITLE = gql`
  query contentTitle($id: String!) {
    content(_id: $id) {
      titulo
      principioAtivo
    }
  }
`

export const GET_DETAILS = gql`
  query detail($content: String!) {
    detail(content: $content) {
      autores {
        nome
      }
      tituloCapitulo
      codigoCapitulo
      tituloLivro
      autoresLivro {
        nome
      }
      copyrightLivro
      isbn
      issn
      anoPublicacao
      dataAtualizacao
      edicaoLivro
      tituloSecao
      tituloCurso
      autoresCurso {
        nome
      }
      autoresModulo {
        nome
      }
      copyrightCurso
      copyrightModulo
      edicaoCurso
      instituicao
      dataPublicacao
      tituloModulo
      editores {
        nome
      }
    }
  }
`
