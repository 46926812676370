import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import Hammer from 'react-hammerjs'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '../icons/IconClose'

const styles = theme => ({
  paper: {
    maxWidth: 400,
    overflow: 'inherit',
    backgroundColor: theme.palette.background.paper
  },
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  closeButton: {
    marginLeft: 'auto',
    position: 'absolute',
    zIndex: 99,
    top: 10,
    right: 10,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  },
  contentWrapper: {
    flex: 1,
    overflow: 'hidden',
    maxHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  }
})

class ImageZoomDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      zoomWidth: window.innerWidth,
      posX: 0,
      posY: 0,
      lastX: 0,
      lastY: 0,
      scale: 1
    }
    this.imageRef = React.createRef()
    this.contentWrapperRef = React.createRef()
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleEntering = () => {
    this.setState({
      width: window.innerWidth,
      zoomWidth: window.innerWidth,
      posX: 0,
      posY: 0,
      lastX: 0,
      lastY: 0,
      scale: 0
    })
  }

  restrictPos = ({ x, y }) => {
    const calcLimitX = () => {
      const limitX = this.imageRef.current.offsetWidth / 2
      const multiplier = x > 0 ? 1 : -1

      return Math.abs(x) > limitX ? limitX * multiplier : x
    }
    const calcLimitY = () => {
      const limitY = this.imageRef.current.offsetHeight / 2
      const multiplier = y > 0 ? 1 : -1

      return Math.abs(y) > limitY ? limitY * multiplier : y
    }
    return { x: calcLimitX(), y: calcLimitY() }
  }

  translate = (deltaX, deltaY, scale) => {
    this.setState(({ lastX, lastY, posX = 0, posY = 0 }) => {
      const deslocamentoX = deltaX - lastX
      const deslocamentoY = deltaY - lastY

      const { x, y } = this.restrictPos({ x: posX + deslocamentoX, y: posY + deslocamentoY })

      return {
        posX: x,
        posY: y,
        lastX: deltaX,
        lastY: deltaY,
        scale
      }
    })
  }
  updateLastPos = (deltaX, deltaY, scale) => {
    this.setState({
      lastX: deltaX,
      lastY: deltaY,
      scale
    })
  }

  restrictZoom = newWidth => {
    const minWidth = window.innerWidth
    const maxWidth = minWidth * 5

    if (newWidth <= minWidth) {
      return minWidth
    } else if (newWidth >= maxWidth) {
      return maxWidth
    } else {
      return newWidth
    }
  }

  getWrapperStyle = pointerType => {
    if (pointerType === 'touch') return {}
    return {
      display: 'block',
      overflow: 'auto'
    }
  }

  render() {
    const { value, searchOrder, classes, content, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        fullScreen
        aria-labelledby="confirmation-dialog-title"
        onEntering={this.handleEntering}
        className={classes.root}
        {...other}
      >
        <React.Fragment>
          <IconButton className={classes.closeButton} onClick={this.handleCancel} aria-label="Sair">
            <IconClose style={{ fontSize: 20 }} viewBox={'0 0 20 20'} />
          </IconButton>
          <Hammer
            options={{
              recognizers: {
                pinch: { enable: true },
                pan: { enable: true }
              }
            }}
            onPan={e => {
              if (e.pointerType === 'touch') {
                this.translate(e.deltaX, e.deltaY, e.scale)
              }
            }}
            onPanStart={e => {
              if (e.pointerType === 'touch') {
                this.setState(state => {
                  return {
                    lastX: e.deltaX,
                    lastY: e.deltaY
                  }
                })
              }
            }}
            onTap={e => {
              if (e.pointerType === 'touch') {
                this.handleEntering()
              }
            }}
            onPinch={e => {
              this.setState(state => {
                return {
                  zoomWidth: this.restrictZoom(state.width * e.scale),
                  scale: e.scale
                }
              })
            }}
            onPinchEnd={e => {
              if (e.pointerType === 'touch') {
                this.setState(state => {
                  return {
                    width: this.restrictZoom(state.width * e.scale),
                    scale: e.scale
                  }
                })
              }
            }}
          >
            <div
              ref={this.contentWrapperRef}
              className={classes.contentWrapper}
              style={this.getWrapperStyle(this.props.pointerType)}
            >
              <img
                ref={this.imageRef}
                style={{
                  width: this.props.pointerType === 'touch' ? `${this.state.zoomWidth}px` : 'auto',
                  transform: `translate(${this.state.posX}px, ${this.state.posY}px)`
                }}
                className={classes.image}
                alt={this.props.image.alt || 'imagem em tamanho ampliado'}
                src={this.props.image.src}
              />
            </div>
          </Hammer>
        </React.Fragment>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ImageZoomDialog)
