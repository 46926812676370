import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { connect } from 'react-redux'
import { snackbarActions } from '../actions'

const AppSnackbar = React.memo(props => {
  const { shouldShowSnackbar, snackbarMessage, hideSnackbar, autoHideDuration = 3500 } = props

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      onClose={() => hideSnackbar()}
      open={shouldShowSnackbar}
      message={snackbarMessage}
    />
  )
})

const mapStateToProps = state => {
  return {
    shouldShowSnackbar: state.snackbar.shouldShowSnackbar,
    snackbarMessage: state.snackbar.message
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideSnackbar: () => {
      dispatch(snackbarActions.hideSnackbar())
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppSnackbar)
