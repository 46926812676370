import { getAccessToken } from '../auth'
const LOGGER_TOKEN_KEY = 'logger-token-key'
const { REACT_APP_LOGGER_URL, REACT_APP_HOST } = process.env

export const insertLoggerToken = payload => {
  setTimeout(() => {
    const $tag = document.createElement('script')
    const clientID = {
      token: getAccessToken(),
      payload,
      REACT_APP_LOGGER_URL,
      REACT_APP_HOST
    }

    $tag.type = 'application/json'
    $tag.id = LOGGER_TOKEN_KEY
    $tag.innerHTML = `${JSON.stringify(clientID)}`
    document.body.appendChild($tag)
  }, 0)
}

export const removeLoggerToken = () => {
  setTimeout(() => {
    const $tag = document.getElementById(LOGGER_TOKEN_KEY)
    if ($tag) {
      $tag.parentNode.removeChild($tag)
    }
  }, 0)
}
