import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const RelativeWrapper = withStyles({
  root: {
    position: 'relative',
    '@media only print': {
      display: 'none !important',
    },
  },
})(({ classes, children, ...rest }) => (
  <div {...rest} className={classes.root}>
    {children}
  </div>
))

export default RelativeWrapper
