import gql from 'graphql-tag'

export const GET_FALE_CONOSCO = gql`
  query faleConosco {
    metatag(chave: "fale_conosco_url") {
      valor
    }
  }
`

export const GET_COLABORE_INTERDRUGS = gql`
  query colaboreInformed {
    metatag(chave: "colabore_informed_url") {
      valor
    }
  }
`
