import { CookieStorage } from 'cookie-storage'
import jwtDecode from 'jwt-decode'
import ApolloClientService from '../ApolloClientService'

const TOKEN = 'token'
const REFRESH_TOKEN = 'refreshToken'
const SSO_TOKEN = 'ssoToken'
const DIRECT_AUTH_SEARCH_PARAMS_KEY = 'DIRECT_AUTH_SEARCH_PARAMS'

const DIRECT_AUTH_KEYS = {
  [TOKEN]: 'direct_token',
  [REFRESH_TOKEN]: 'direct_refreshToken',
  [SSO_TOKEN]: 'direct_ssoToken'
}

export const makeCookieStorage = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const expires = new Date(year + 200, month, day)
  return new CookieStorage({
    expires,
    sameSite: 'None',
    secure: process.env.NODE_ENV === 'production'
  })
}

const cookieStorage = makeCookieStorage()

export const isDirectAuth = () => window.parent !== window

const getStorageAuthKey = key => (isDirectAuth() ? DIRECT_AUTH_KEYS[key] : key)

export const getDirectAuthCredentials = () => {
  const MY_CREDENTIALS = atob(window.localStorage.getItem(DIRECT_AUTH_SEARCH_PARAMS_KEY))
  return MY_CREDENTIALS
}

export const saveDirectAuthCredentials = credentials => {
  if (credentials) {
    const MY_CREDENTIALS = window.btoa(credentials)
    window.localStorage.setItem(DIRECT_AUTH_SEARCH_PARAMS_KEY, MY_CREDENTIALS)
  }
}

export const getAccessToken = () => cookieStorage.getItem(getStorageAuthKey(TOKEN)) || null

export const getRefreshToken = () => cookieStorage.getItem(getStorageAuthKey(REFRESH_TOKEN))

export const decodedToken = () => (getAccessToken() && jwtDecode(getAccessToken())) || {}

export const isDirectToken = () => {
  const { direct } = decodedToken()
  return !!direct
}

export const getUserId = () => decodedToken(getAccessToken()).userId || ''

export const onLogout = () => {
  ApolloClientService.clearCache()
  cookieStorage.clear()
  window.closeWS && window.closeWS()
}

export const getSSOToken = () => cookieStorage.getItem(getStorageAuthKey(SSO_TOKEN))

export const login = ({ token, refreshToken, ssoToken }) => {
  cookieStorage.setItem(getStorageAuthKey(TOKEN), token)
  cookieStorage.setItem(getStorageAuthKey(REFRESH_TOKEN), refreshToken)
  cookieStorage.setItem(getStorageAuthKey(SSO_TOKEN), ssoToken)
  window.resetWS && window.resetWS()
}

export const updateToken = token => {
  cookieStorage.setItem(TOKEN, token)
  window.resetWS && window.resetWS()
}

export const updateRefreshToken = refreshToken => {
  cookieStorage.setItem(REFRESH_TOKEN, refreshToken)
  window.resetWS && window.resetWS()
}
