import gql from 'graphql-tag'

export const UPDATE_USER = gql`
  mutation updateUser(
    $name: String
    $lastname: String
    $email: String
    $gender: String
    $speciality: [String]
  ) {
    updateUser(
      name: $name
      lastname: $lastname
      email: $email
      gender: $gender
      speciality: $speciality
    )
  }
`

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $newPassword: String!, $email: String!) {
    updatePassword(password: $password, newPassword: $newPassword, email: $email)
  }
`
