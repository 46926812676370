import gql from 'graphql-tag'

export const GET_AUTHOR = gql`
  query author($_id: String!) {
    author(_id: $_id) {
      nome
      especializacao
      foto
      curriculo
    }
  }
`
