import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import contentTypeIcons from '../icons/contentTypeIcons'

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    width: 48,
    height: 48,
    flex: 'none',
    marginRight: 3,
    marginLeft: 8,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  typeImage: {
    maxWidth: '100%'
  },
  imageFavorite: {
    width: 16,
    position: 'absolute',
    right: 0,
    bottom: -10
  },
  imageNote: {
    width: 16,
    position: 'absolute',
    left: 0,
    bottom: -10
  }
})

const ResultItemIcon = ({ classes, gravidade, corGravidade }) => {
  if (gravidade == 'Maior') {
    return (
      <div
        className={classes.root}
        style={corGravidade ? { backgroundColor: corGravidade } : { backgroundColor: '#DC143C' }}
      >
        <img className={classes.typeImage} src={contentTypeIcons[gravidade]} alt={gravidade} />
      </div>
    )
  } else if (gravidade == 'Moderada') {
    return (
      <div
        className={classes.root}
        style={corGravidade ? { backgroundColor: corGravidade } : { backgroundColor: '#FFA500' }}
      >
        <img className={classes.typeImage} src={contentTypeIcons[gravidade]} alt={gravidade} />
      </div>
    )
  } else {
    return (
      <div
        className={classes.root}
        style={corGravidade ? { backgroundColor: corGravidade } : { backgroundColor: '#3CB371' }}
      >
        <img className={classes.typeImage} src={contentTypeIcons[gravidade]} alt={gravidade} />
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ResultItemIcon)
