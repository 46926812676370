import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Font from '../icons/Font'
import FontDecrease from '../icons/FontDecrease'
import FontIncrease from '../icons/FontIncrease'
import { safeLocalStorage } from '../utils'

const styles = theme => ({
  wrapper: {
    display: 'inline',
    position: 'relative'
  },
  controls: {
    position: 'absolute',
    top: 42,
    background: '#229FFF',
    display: 'flex',
    borderRadius: 8,
    right: -42
  },
  arrow: {
    display: 'block',
    width: 20,
    height: 20,
    position: 'absolute',
    background: '#229FFF',
    transform: 'rotate(45deg)',
    left: 0,
    right: 0,
    top: -10,
    margin: 'auto'
  },
  divider: {
    width: 1,
    height: '70%',
    background: '#fff',
    display: 'block',
    zIndex: 999,
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    top: 0,
    bottom: 0
  },
  controlButton: {
    marginLeft: 10,
    marginRight: 10
  }
})

class FontZoom extends React.PureComponent {
  constructor(props) {
    super(props)
    const userFontSizeScale =
      !!safeLocalStorage() && Number(safeLocalStorage().getItem('font-size-scale'))

    this.state = {
      open: false,
      scale: 0
    }

    this.state.scale = userFontSizeScale || 0
    this.buttonRef = React.createRef()
    this.ZoomFontWrapperRef = React.createRef()
  }

  toggleFontControl = () => {
    this.setState(state => ({
      open: !state.open
    }))
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('touchstart', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('touchstart', this.handleClickOutside)
  }

  handleClickOutside = e => {
    const $zoomFont = this.ZoomFontWrapperRef.current
    if ($zoomFont && $zoomFont.contains(e.target)) return
    this.setState({
      open: false
    })
  }

  updateFontSize = () => {
    const ContentPreviewElements = document.querySelectorAll('.ContentPreview')
    if (!ContentPreviewElements || !ContentPreviewElements.length) return

    const newSize = `${0.7 + this.state.scale / 10}rem`

    for (let contentPreview of ContentPreviewElements) {
      contentPreview && contentPreview.style.setProperty('--content-base-font-size', newSize)
    }
  }

  restrictScale = (scale, direction) => {
    const minScale = -3
    const maxScale = 5
    const newScale = scale + direction
    if (direction < 0) {
      return scale > minScale ? newScale : minScale
    } else {
      return scale < maxScale ? newScale : maxScale
    }
  }

  increase = () => {
    this.setState(state => {
      const newScale = this.restrictScale(state.scale, 1)
      safeLocalStorage() && safeLocalStorage().setItem('font-size-scale', newScale)
      return {
        scale: newScale
      }
    }, this.updateFontSize)
  }

  decrease = () => {
    this.setState(state => {
      const newScale = this.restrictScale(state.scale, -1)
      safeLocalStorage() && safeLocalStorage().setItem('font-size-scale', newScale)
      return {
        scale: newScale
      }
    }, this.updateFontSize)
  }

  render() {
    const { classes, disabled } = this.props
    const { open } = this.state

    return (
      <div className={classes.wrapper} ref={this.ZoomFontWrapperRef}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          className={classes.menuButton}
          disabled={disabled}
          onClick={() => {
            this.toggleFontControl()
          }}
        >
          <Font style={{ color: disabled ? 'rgba(255,255,255,.4)' : '#fff' }} />
        </IconButton>
        {!!open && (
          <div className={classes.controls}>
            <span className={classes.arrow} />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              className={classes.controlButton}
              onClick={() => {
                this.decrease()
              }}
            >
              <FontDecrease />
            </IconButton>
            <span className={classes.divider} />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              className={classes.controlButton}
              onClick={() => {
                this.increase()
              }}
            >
              <FontIncrease />
            </IconButton>
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(FontZoom)
