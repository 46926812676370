import gql from 'graphql-tag'

export const GET_FAQ = gql`
  query {
    faq {
      sequencia
      pergunta
      resposta
    }
  }
`
