import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '../icons/IconClose'
import { authorQueries } from '../graphql/queries'
import AuthorImage from './AuthorImage'

const styles = theme => ({
  paper: {
    maxWidth: 400,
    overflow: 'inherit',
    backgroundColor: theme.palette.background.paper
  },
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  content: {
    minHeight: 60,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  foto: {
    width: 59,
    height: 59,
    borderRadius: '50%'
  },
  nome: {
    color: '#797979',
    fontSize: 24,
    fontWeight: '400',
    margin: 0
  },
  especializacao: {
    color: '#6484b0',
    fontSize: 16
  },
  header: {
    paddingTop: 24,
    padding: 0,
    textAlign: 'center'
  },
  headerName: {
    marginTop: 20
  },
  curriculo: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: '1em',
    overflow: 'auto',
    '@media only screen and (orientation: landscape)': {
      overflow: 'initial'
    }
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  }
})

class AuthorDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anotacao: ''
    }
  }

  handleCancel = () => {
    this.props.onClose()
  }

  changeNote = anotacao => {
    this.setState({ anotacao })
  }

  renderSpinner = () => {
    const { classes } = this.props
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} size={30} />
      </div>
    )
  }

  render() {
    const { value, searchOrder, classes, content, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        className={classes.root}
        {...other}
      >
        <IconButton className={classes.closeButton} onClick={this.handleCancel} aria-label="Sair">
          <IconClose style={{ fontSize: 20 }} viewBox={'0 0 20 20'} />
        </IconButton>
        <Query
          query={authorQueries.GET_AUTHOR}
          fetchPolicy={'cache-and-network'}
          variables={{ _id: this.props.open }}
        >
          {({ loading, error, data }) => {
            const { author = {} } = data
            return (
              <React.Fragment>
                <DialogContent className={classes.content}>
                  <DialogTitle className={classes.header}>
                    <div>
                      <AuthorImage author={author} />
                    </div>
                    <div className={classes.headerName}>
                      <h3 id={'confirmation-dialog-title'} className={classes.nome}>
                        {author.nome}
                      </h3>
                      <span className={classes.especializacao}>{author.especializacao}</span>
                    </div>
                  </DialogTitle>
                  <div className={classes.curriculo}>{author.curriculo}</div>
                </DialogContent>
              </React.Fragment>
            )
          }}
        </Query>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AuthorDialog)
