import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login($email: String!, $password: String!, $fingerprint: String!) {
    login(email: $email, password: $password, fingerprint: $fingerprint) {
      token
      ssoToken
      refreshToken
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation refreshToken($uuid: String!) {
    refreshToken(uuid: $uuid) {
      token
      refreshToken
    }
  }
`
