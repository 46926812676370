import { searchMedicationActions } from '../actions'
const {
  CHANGE_TERM_MEDICATION,
  CHANGE_FILTER_MEDICATION,
  RESET_FILTER_MEDICATION
} = searchMedicationActions

const getInitialState = () => ({
  term: ''
})

const searchMedication = (state = getInitialState(), action = '') => {
  switch (action.type) {
    case CHANGE_TERM_MEDICATION:
      return { ...state, term: action.term }
    case CHANGE_FILTER_MEDICATION:
      return { ...state, filter: action.filter }
    case RESET_FILTER_MEDICATION:
      return { ...state, filter: getInitialState().filter }
    default:
      return state
  }
}

export default searchMedication
