import gql from 'graphql-tag'

export const GET_NOTIFICATION = gql`
  query {
    notification {
      id
      title
      message
    }
  }
`
