import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export const PenFull = props => (
  <SvgIcon {...props}>
    <path
      id="pen_full"
      fill={'inherit'}
      d="M21.1,6.6c-0.3,0.4-0.8,0.8-0.8,1.1s0.3,0.6,0.6,1.1c0.5,0.5,1.1,1,1,1.5c0,0.5-0.5,1.1-1.1,1.6
	l-4.4,4.4L15,15l4.5-4.5l-1.1-1.1l-1.5,1.5l-4.1-4.1l4.3-4.1c0.4-0.4,1.1-0.4,1.5,0c0,0,0,0,0,0l2.5,2.5C21.5,5.5,21.6,6.2,21.1,6.6
	C21.1,6.6,21.1,6.6,21.1,6.6 M2,17.8L12.4,7.4l4,4.1L6.1,21.8H2V17.8z"
    />
  </SvgIcon>
)

export default PenFull
