import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ErrorOutlineIcon from '@material-ui/icons/CloudOff'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  icon: {
    fontSize: 70,
    color: '#757575'
  }
})

const DeletedContentError = ({ classes }) => {
  return (
    <div className={classes.root}>
      <ErrorOutlineIcon className={classes.icon} />
      <Typography gutterBottom className={classes.title} variant={'title'}>
        Ooops
      </Typography>
      <Typography
        gutterBottom
        className={classes.title}
        style={{ fontSize: 16, textAlign: 'center' }}
      >
        Este conteúdo não está disponível.
      </Typography>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(DeletedContentError)
