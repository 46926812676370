import React from 'react'
import SearchResultPlaceholder from './SearchResultPlaceholder'
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ReactPlaceholder from 'react-placeholder'
import { prescriptionQueries } from '../../../graphql/queries'
import { searchMedicationActions } from '../../../actions'
import Query from 'react-apollo/Query'
import ErrorHandling from '../../../components/ErrorHandling'
import { connect } from 'react-redux'
import SearchResultListScroll from './SearchResultListScroll'
import SearchResultListPlaceholder from '../../../components/Placeholder/SearchResult/List'
import LoadMorePlaceholder from '../../../components/Placeholder/SearchResult/LoadMore'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { medicationListActions } from '../../../actions'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    '@media only print': {
      display: 'none !important'
    },
    '@media only screen and (max-width: 900px)': {
      overflow: 'scroll',
    }
  },
  list: {
    paddingTop: 0,
    width: '100%',
    position: 'relative',
    transition: '250ms',
    marginBottom: '55px'
  },
  item: {
    minHeight: '50px',
    flex: 1,
    paddingLeft: 22,
    paddingRight: 22,
    alignItems: 'center',
    wordBreak: 'break-word',
    background: '#fff',
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    paddingBottom: 0,
    transition: '250ms',
    '&:hover': {
      backgroundColor: '#A7D4B9',
      color: '#FFF',
      cursor: 'pointer'
    }
  },
  itemTitle: {
    color: '#5d5c5c',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical'
    }
  },
  resultPlaceholderWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  itemDescription: {
    fontSize: 14,
    marginTop: 4,
    '& em': {
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    '& > div': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 3,
      '-webkit-box-orient': 'vertical'
    }
  },
  selectedOption: {
    minHeight: '50px',
    flex: 1,
    paddingLeft: 22,
    paddingRight: 22,
    alignItems: 'center',
    wordBreak: 'break-word',
    background: '#fff',
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    paddingBottom: 0,
    transition: '250ms',
    backgroundColor: '#45B384',
    color: '#FFF',
  },
  itemIconButton: {
    marginLeft: 'auto',
    marginTop: 4,
    '& em': {
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    '& > div': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 3,
      '-webkit-box-orient': 'vertical'
    }
  },
  title: {
    color: '#505152',
    padding: 0,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22,
    paddingRight: 0,
    display: 'flex',
    minHeight: 46,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  numberResults: {
    color: '#5d5c5c',
    fontSize: 12,
    fontWeight: 'bold'
  },
  optionsButton: {
    color: '#fff',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 5,
    marginRight: 10,
    minHeight: 28,
    '&:hover, &:focus': {
      background: '#959595'
    }
  },
  optionsButtonIcon: {
    fontSize: 22
  },
  switchControlLabel: {
    '& > span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  optionsButton: {
    color: '#fff',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 5,
    marginRight: 10,
    minHeight: 28,
    background: '#1e90ff',
    '&:hover, &:focus': {
      background: '#035dcd'
    }
  },
  optionsButtonIcon: {
    fontSize: 22
  },
  scrollContent: {
    overflow: 'auto'
  }
})

class SearchResultList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {selectedPrescription: ''}
  }

  onSelect = ( id, nome ) => {
    this.props.addMedicineLeaflet({ id, nome })
    this.setState({ selectedPrescription: id })
  }

  componentWillUnmount() {
    this.props.changeTerm('')
  }

  render() {
    const { classes, searchTerm } = this.props
    return (
      <Query
        query={prescriptionQueries.SEARCH_PRESCRIPTION}
        variables={{
          term: searchTerm,
          offset: 0
        }}
      >
        {({ loading, error, data, refetch, networkStatus, fetchMore }) => {
          if (loading) {
            return (
              <ReactPlaceholder
                children={<div />}
                showLoadingAnimation={true}
                type="media"
                ready={false}
                rows={2}
                customPlaceholder={SearchResultListPlaceholder}
              />
            )
          }

          if (error) {
            return (
              <ErrorHandling
                error={error}
                refetch={() => refetch()}
                networkStatus={networkStatus}
              />
            )
          }

          if (!((data.medicineLeaflet || {}).items || []).length) {
            return (
              <div className={classes.resultPlaceholderWrapper}>
                <SearchResultPlaceholder />
              </div>
            )
          }

          return (
            <SearchResultListScroll
              className={classes.searchResultListScroll, classes.scrollContent}
              parentElementId={'infinite-search-medication-result-list'}
              id={'infinite-search-medication-result-list'}
              loadMore={() => {
                fetchMore({
                  variables: {
                    offset: data.medication.items.length
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev
                    return {
                      ...prev,
                      medication: {
                        ...prev.medication,
                        items: [...prev.medication.items, ...fetchMoreResult.medication.items]
                      }
                    }
                  }
                })
              }}
              useWindow={false}
              hasMore={data.medicineLeaflet.items.length < data.medicineLeaflet.total}
              loader={
                <div key={'placeholder-loading'}>
                  <ReactPlaceholder
                    children={<div />}
                    showLoadingAnimation={true}
                    type="media"
                    ready={false}
                    rows={2}
                    customPlaceholder={LoadMorePlaceholder}
                  />
                </div>
              }
            >
              <div className={classes.root}>
                <List className={`${classes.list} ${this.state.selectedPrescription ? classes.hasSelectedOption : ''}`}>
                  {data.medicineLeaflet.items.map(({ _id, principioAtivo }) => {
                    return (
                      <div key={_id}>
                        <ListItem 
                          className={_id === this.state.selectedPrescription ? classes.selectedOption : classes.item}
                          component={'div'}
                          onClick={() => {this.onSelect(_id, principioAtivo)}}
                        >
                          <div className={classes.itemDescription}>
                            <span>{principioAtivo.toUpperCase()}</span>
                          </div>
                        </ListItem>
                      </div>
                    )
                  })}
                </List>
              </div>
            </SearchResultListScroll>
          )
        }}
      </Query>
    )
  }
}

const mountButton = ({ id }, listMedication) => {
  let selected = false

  listMedication.map(item => {
    if (item.id === id) selected = true
  })

  if (selected === false) {
    return <AddCircleIcon style={{ color: '#3eaa68' }} />
  } else {
    return <RemoveCircleIcon style={{ color: '#e25050' }} />
  }
}

const mapStateToProps = state => {
  return {
    selectedList: state.medicationList.selectedList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeTerm: term => {
      dispatch(searchMedicationActions.changeTerm(term))
    },
    addMedicineLeaflet: medicineLeaflet => {
      dispatch(medicationListActions.addMedicineLeaflet(medicineLeaflet))
    },
    removeMedication: medication => {
      dispatch(medicationListActions.removeMedication(medication))
    },
    clearMedicationList: () => {
      dispatch(medicationListActions.clearMedicationList())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withStyles(styles, { withTheme: true })(SearchResultList))
