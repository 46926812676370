import { contentScrollActions } from '../actions'
const { SET_CONTENT_SCROLL } = contentScrollActions

const initialState = {
  scrollTop: 0,
  clientHeight: 0,
  contentId: ''
}

const contentScroll = (state = initialState, action = '') => {
  const { type, payload } = action
  switch (type) {
    case SET_CONTENT_SCROLL:
      return {
        ...state,
        scrollTop: payload.scrollTop,
        clientHeight: payload.clientHeight,
        contentId: payload.contentId
      }
    default:
      return state
  }
}

export default contentScroll
