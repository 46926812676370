/**
 * Componente para tratar responsividade Desktop/Mobile
 * Mobile: < 940px
 * Desktop: >= 940px
 * @component
 *
 * @example usando apenas desktop
 * <SwitchDesktopMobile desktop>
 *   <h1>Only Desktop</h1>
 * </SwitchDesktopMobile>
 *
 * @example usando apenas mobile
 * <SwitchDesktopMobile mobile>
 *   <h1>Only Mobile</h1>
 * </SwitchDesktopMobile>
 *
 * @example usando mobileComponent e desktopComponent
 * <SwitchDesktopMobile mobileComponent={SearchbarMobile} desktopComponent={SearchbarDesktop} />
 *
 */
import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

const SwitchDesktopMobile = ({
  children,
  desktop = false,
  mobile = false,
  mobileComponent: MobileComponent = null,
  desktopComponent: DesktopComponent = null
}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 940px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 939px)' })

  if (isDesktop && desktop) {
    return children
  } else if (isMobile && mobile) {
    return children
  }

  if (isDesktop && DesktopComponent) {
    return <DesktopComponent />
  } else if (isMobile && MobileComponent) {
    return <MobileComponent />
  }

  return null
}

SwitchDesktopMobile.propTypes = {
  children: PropTypes.element.isRequired
}

export default SwitchDesktopMobile
