import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

function BulaInteracaoIcon(props) {
  return (
    <SvgIcon {...props}>
      <path data-name="União 2" d="M14.594 18.5a2.127 2.127 0 00.742-1.6V2.091a2.126 2.126 0 00-.742-1.595h.706a1.679 1.679 0 011.743 1.595V16.9a1.68 1.68 0 01-1.743 1.6zm-2.615 0a2.127 2.127 0 00.743-1.6V2.091a2.127 2.127 0 00-.743-1.595h.706a1.679 1.679 0 011.743 1.595V16.9a1.68 1.68 0 01-1.743 1.6zm-9.946 0a1.57 1.57 0 01-1.534-1.6V2.091A1.57 1.57 0 012.033.496h8.132a1.57 1.57 0 011.534 1.595V16.9a1.57 1.57 0 01-1.534 1.6zM5.289 8.183l1.345 1.4a1.4 1.4 0 002.036 0 1.546 1.546 0 000-2.119l-1.345-1.4zm-1.812-4a1.546 1.546 0 000 2.119l1.388 1.444 2.036-2.119-1.387-1.444a1.41 1.41 0 00-1.019-.437 1.407 1.407 0 00-1.017.434z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"/>
    </SvgIcon>
  );
}
{/* <svg xmlns="http://www.w3.org/2000/svg" width="17.544" height="19"></svg> */}
export default BulaInteracaoIcon