import medicamentoIcon from './svg/medicamento.svg'
import Maior from './svg/Maior.svg'
import Menor from './svg/Menor.svg'
import Moderada from './svg/Moderada.svg'

const contentTypeIcons = {
  medicamento: medicamentoIcon,
  Maior,
  Menor,
  Moderada
}

export default contentTypeIcons
