import React, { useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import SearchResultList from './SearchResultList'
import OnlyOnline from '../../components/OnlyOnline'
import SearchBarMedication from './SearchBar'
import { connect } from 'react-redux'
import { searchMedicationActions } from '../../actions'
import ResultSearchDesktop from './SearchResultList/ResultSearchDesktop'
import MedicationInteractionDesktop from '../MedicationInteractionDesktop'
import CustomSearchBar from '../Medication/SearchBar/CustomSearchbar'
import medicationListImage from '../../images/interdrugs_bg.jpg'
import InformationInteractionDesktop from '../InformationInteractionDesktop'

const styles = (theme) => ({
  searchComponent: {
    width: '100%',
    flex: 1,
    display: 'flex',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    flexDirection: 'column',
    height: '100%',
    // '@media only and screen (max-width: 800px)': {
    //   height: 'calc(100% - 64px)',
    // }
  },
  wrapper: {
    display: 'flex',
    height: '100%',
  },
  resultDetails: {
    flex: 1,
    background: `url(${medicationListImage}) no-repeat top center`,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  resultDetailsText: {
    width: '100%',
    margin: 'auto',
    backgroundColor: 'rgba(255, 255, 255, .7)',
  },
  resultDetailsStyleParagraph: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: 20,
    marginRight: 20,
  },
  sideBar: {
    position: 'relative',
    width: 360,
    background: '#F7F7F7',
    '@media only print': {
      display: 'none',
    },
  },
  SearchResultList: {
    marginTop: 48,
  },
})

const Medication = (props) => {
  const {
    classes,
    searchTerm,
    showSearchBar,
    consultInteractionsList,
    interactionsInformations,
  } = props

  useEffect(() => {
    const $switchWrapper = document.querySelector('.switch-wrapper')
    $switchWrapper.classList.add('switch-without-search-bar')
    return () => {
      $switchWrapper.classList.remove('switch-without-search-bar')
    }
  }, [])

  return (
    <OnlyOnline>
      <Paper className={classes.wrapper}>
        {consultInteractionsList === '' ? (
          <Paper className={classNames(classes.sideBar, 'hidden-print')}>
            <CustomSearchBar className={classes.customSearchBar} />
            <div className={classes.searchComponent}>
              <SearchBarMedication />
              {!showSearchBar ? <ResultSearchDesktop /> : <SearchResultList />}
            </div>
          </Paper>
        ) : (
          <MedicationInteractionDesktop />
        )}

        <div className={classes.resultDetails}>
          {interactionsInformations === '' ? (
            <div className={classes.resultDetailsText}>
              <p className={classes.resultDetailsStyleParagraph}>
                <ul>
                  <li>Plataforma inovadora de interações medicamentosas 100% em português.</li> 
                  <li>Elaborada e continuamente atualizada por farmacêuticos com experiência clínica e formação acadêmica consistente.</li>
                  <li>Interações medicamentosas classificadas de acordo com a gravidade e a probabilidade de ocorrência, com base em estudos de cenário real.</li>
                  <li>Sugestão de condutas e indicação de parâmetros clínicos para monitoramento de interações potenciais.</li>
                  <li>Dados obtidos de artigos científicos e informações dos fabricantes nas bulas, submetidos a avaliação e adequação às características e particularidades dos serviços de saúde nacionais.</li>
                </ul>
	      </p>
            </div>
          ) : (
            <InformationInteractionDesktop />
          )}
        </div>
      </Paper>
    </OnlyOnline>
  )
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.searchMedication.term,
    showSearchBar: state.medicationList.showSearchBar,
    consultInteractionsList: state.medicationList.consultInteractionsList,
    interactionsInformations: state.medicationList.interactionsInformations,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTerm: (term) => {
      dispatch(searchMedicationActions.changeTerm(term))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Medication))
