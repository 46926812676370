import gql from 'graphql-tag'

export const GET_MESSAGES = gql`
  query {
    messages {
      id
      titulo
      mensage
      viewed
    }
  }
`

export const CHECK_NEW_MESSAGES = gql`
  query {
    checkNewMessages
  }
`
