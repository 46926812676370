import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import RateReview from '@material-ui/icons/RateReview'
import FeedbackDialog from './FeedbackDialog'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 16,
    paddingBottom: 16
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
})

const Feedback = props => {
  const { classes } = props
  const [showFeedbackDialog, setFeedbackDialog] = useState(false)

  return (
    <div className={classes.root}>
      <Button color={'primary'} onClick={() => setFeedbackDialog(true)}>
        <RateReview className={classes.leftIcon} /> Avaliar este conteúdo
      </Button>
      <FeedbackDialog
        open={showFeedbackDialog}
        contentId={props.contentId}
        onClose={() => {
          setFeedbackDialog(false)
        }}
      />
    </div>
  )
}

export default React.memo(withStyles(styles)(Feedback))
