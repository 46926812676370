import gql from 'graphql-tag'

export const SEARCH_MEDICATION = gql`
  query medication($term: String!, $offset: Int) {
    medication(term: $term, offset: $offset) {
      total
      items {
        id
        nome
      }
    }
  }
`
