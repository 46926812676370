import { TokenRefreshLink } from 'apollo-link-token-refresh'
import jwtDecode from 'jwt-decode'
import * as API from '../api'
import * as authService from '../services/auth'

const unauthorized = () => {
  authService.onLogout()
  window.location.pathname = '/'
}

const isTokenExpired = () => {
  const token = authService.getAccessToken()
  const jwt = token ? jwtDecode(token) : {}
  const currentTime = token ? new Date().getTime() / 1000 : null

  return currentTime ? currentTime > jwt.exp : true
}

const linkRefresh = () => {
  return new TokenRefreshLink({
    accessTokenField: 'token',
    isTokenValidOrUndefined: () =>
      !isTokenExpired() || typeof authService.getAccessToken() !== 'string',
    fetchAccessToken: () => {
      return API.auth.refreshToken({
        accessToken: authService.getAccessToken(),
        refreshToken: authService.getRefreshToken()
      })
    },
    handleFetch: token => {
      token && authService.updateToken(token)
    },
    handleResponse: (operation, accessTokenField) => async response => {
      const token = await response.json().then(json => {
        const { refreshToken, token } = json || {}
        refreshToken && token && authService.updateRefreshToken(refreshToken)
        return token
      })

      return { token }
    },
    handleError: err => {
      unauthorized()
    }
  })
}

export default linkRefresh
