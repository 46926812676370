import { medicationListActions } from '../actions'
const {
  ADD_MEDICINELEAFLET,
  REMOVE_MEDICINELEAFLET,
  ADD_MEDICATION,
  REMOVE_MEDICATION,
  CLEAR_MEDICATION_LIST,
  CHANGE_SHOW_SEARCH_BAR,
  INTERACTIONS_CONSULT,
  CHANGE_INTERACTIONS_CONSULT,
  INTERACTION_INFORMATIONS_CONSULT,
  CHANGE_INTERACTIONS_INFORMATIONS,
  INTERACTIONS_RESULTS,
  RESET_INTERACTIONS_RESULTS,
  STORE_INTERACTIONS_INFORMATIONS
} = medicationListActions

const getInitialState = () => ({
  selectedList: [],
  interactionsResults: [],
  consultInteractionsList: '',
  interactionsInformations: '',
  showSearchBar: false,
  prescriptionsList: [],
  medicineLeaflet: {}
})


const medicationList = (state = getInitialState(), action = '') => {
  switch (action.type) {
    case ADD_MEDICINELEAFLET:
      return { ...state, medicineLeaflet: action.medicineLeaflet }
    case REMOVE_MEDICINELEAFLET:
      return { ...state, medicineLeaflet: '' }
    case ADD_MEDICATION:
      return { ...state, selectedList: [...state.selectedList, action.medication] }
    case REMOVE_MEDICATION:
      return {
        ...state,
        selectedList: state.selectedList.filter(item => item.id !== action.medication.id)
      }
    case CLEAR_MEDICATION_LIST:
      return { ...state, selectedList: [] }

    case CHANGE_SHOW_SEARCH_BAR:
      return { ...state, showSearchBar: action.value }

    case INTERACTIONS_CONSULT:
      return { ...state, consultInteractionsList: action.medications }

    case CHANGE_INTERACTIONS_CONSULT:
      return { ...state, consultInteractionsList: '' }

    case INTERACTION_INFORMATIONS_CONSULT:
      return { ...state, interactionsInformations: action.informations }

    case CHANGE_INTERACTIONS_INFORMATIONS:
      return { ...state, interactionsInformations: '' }

    case INTERACTIONS_RESULTS:
      return { ...state, interactionsResults: action.interactionsResults }

    case STORE_INTERACTIONS_INFORMATIONS:
      return { ...state, prescriptionsList: action.prescription }

    case RESET_INTERACTIONS_RESULTS:
      return { ...state, interactionsResults: [] }

    default:
      return state
  }
}

export default medicationList
