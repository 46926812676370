export default history => (state, action) => {
  const { payload, type } = action
  if (type === 'persist/REHYDRATE' && payload && payload.location) {
    const fromAnotation = ((payload.location || {}).state || {}).from === 'anotacoes'

    if (((history || {}).location || {}).pathname === '/') {
      const newLocation = {
        ...payload.location,
        state: {
          ...payload.location.state,
          recycle: true
        }
      }
      const initialPath = fromAnotation ? '/anotacoes' : '/'
      history.replace(initialPath)
      history.push(newLocation)
    }
  }

  return state || {}
}
