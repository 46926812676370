import React, { useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import SearchResultList from './SearchResultList'
import OnlyOnline from '../../components/OnlyOnline'
import SearchBarMedication from '../Medication/SearchBar'
import { connect } from 'react-redux'
import { searchMedicationActions } from '../../actions'
import PrescriptionPlaceholder from './PrescriptionPlaceholder'
import CustomSearchBar from '../Medication/SearchBar/CustomSearchbar'
import medicationImage from '../../images/medicine-bg.jpg'
import Content from '../Content'
import * as ContentType from 'interdrugs-shared/components/es/ContentType'

const styles = (theme) => ({
  searchComponent: {
    width: '100%',
    flex: 1,
    display: 'flex',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    flexDirection: 'column',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    height: '100%',
  },
  resultDetails: {
    flex: 1,
    background: `url(${medicationImage}) no-repeat center`,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  resultDetailsText: {
    width: '100%',
    margin: 'auto',
    backgroundColor: 'rgba(255, 255, 255, .7)',
  },
  resultDetailsStyleParagraph: {
    color: '#3A3939',
    fontWeight: 600,
    marginLeft: 20,
  },
  sideBar: {
    position: 'relative',
    width: 360,
    zIndex: 2,
    '@media only print': {
      display: 'none',
    },
  },
})

const PrescriptionDesktop = (props) => {
  const { classes, showSearchBar, selectedPrescription, medicineLeaflet } = props

  useEffect(() => {
    const $switchWrapper = document.querySelector('.switch-wrapper')
    $switchWrapper.classList.add('switch-without-search-bar')
    return () => {
      $switchWrapper.classList.remove('switch-without-search-bar')
    }
  }, [])

  return (
    <OnlyOnline>
      <Paper className={classes.wrapper}>
        <Paper className={classNames(classes.sideBar, 'hidden-print')}>
          <CustomSearchBar className={classes.customSearchBar} />
          <div className={classes.searchComponent}>
            <SearchBarMedication />
            {!showSearchBar ? <PrescriptionPlaceholder /> : <SearchResultList />}
          </div>
        </Paper>

        <div className={classes.resultDetails}>
          {!medicineLeaflet ? (
            <div className={classes.resultDetailsText}>
              <p className={classes.resultDetailsStyleParagraph}>
                <ul>
                  <li>Plataforma inovadora de interações medicamentosas 100% em português.</li> 
                  <li>Elaborada e continuamente atualizada por farmacêuticos com experiência clínica e formação acadêmica consistente.</li>
                  <li>Interações medicamentosas classificadas de acordo com a gravidade e a probabilidade de ocorrência, com base em estudos de cenário real.</li>
                  <li>Sugestão de condutas e indicação de parâmetros clínicos para monitoramento de interações potenciais.</li>
                  <li>Dados obtidos de artigos científicos e informações dos fabricantes nas bulas, submetidos a avaliação e adequação às características e particularidades dos serviços de saúde nacionais.</li>
                </ul>
              </p>
            </div>
          ) : (
            <Content />
          )}
        </div>
      </Paper>
    </OnlyOnline>
  )
}

const mapStateToProps = (state) => {
  return {
    showSearchBar: state.medicationList.showSearchBar,
    consultInteractionsList: state.medicationList.consultInteractionsList,
    interactionsInformations: state.medicationList.interactionsInformations,
    medicineLeaflet: state.medicationList.medicineLeaflet,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTerm: (term) => {
      dispatch(searchMedicationActions.changeTerm(term))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrescriptionDesktop))
