import gql from 'graphql-tag'

export const GET_NOTE = gql`
  query note($content: String!) {
    note(content: $content) {
      anotacao
    }
  }
`

export const GET_NOTES = gql`
  query {
    notes {
      anotacao
      conteudo {
        _id
      }
    }
  }
`
