import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export const Font = props => (
  <SvgIcon {...props}>
    <path
      id="font"
      fill={'inherit'}
      d="M21.6,20.2L20,16h-6.9l-1.5,4.3H9.4l6.2-16.4h1.9l6.3,16.4H21.6z M13.8,14.2h5.6l-2.8-7.6L13.8,14.2z
	 M7.2,19.6l-0.9-2.4H2.4l-0.9,2.4H0.3l3.5-9.2h1.1l3.5,9.2H7.2z M2.8,16.2h3.1l-1.6-4.3L2.8,16.2z"
    />
  </SvgIcon>
)

export default Font
