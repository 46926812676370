import React from 'react'

/**
 * @param component - React Component
 * @param {Object} options - Lista de opcoes para o componente
 * @param {array} options.props - lista de props que devem ter debounce
 * @param {Object} options.props[].name - nome da prop que deve ter debounce
 * @param {Object} options.props[].delay - tempo a esperar para atualizar a prop
 * @param {Object} options.props[].defaultValue - valor padrão da prop
 *
 * @example
 * const options = {
 *   props: [{ name: 'searchTerm', delay: 500, defaultValue: '' }]
 * }
 * withDebouncedProps(MyComponent, options)
 *
 * @returns {function} - Retorna um React Component
 */

class DebouncedProps extends React.Component {
  constructor(props) {
    const { options } = props
    super(props)
    this.state = {}
    this.debouncedTimeoutKeys = {}

    options.props.forEach(item => {
      // eslint-disable-next-line
      this.state[item.name] = props[item.name] || item.defaultValue
    })

    this.updateDebouncedProps()
  }

  updateDebouncedProps = () => {
    this.props.options.props.forEach(item => {
      this.debouncedTimeoutKeys[item.name] && clearTimeout(this.debouncedTimeoutKeys[item.name])

      this.debouncedTimeoutKeys[item.name] = setTimeout(() => {
        if (this.props[item.name] !== this.state[item.name]) {
          this.setState(state => {
            return { ...state, [item.name]: this.props[item.name] }
          })
        }
      }, item.delay)
    })
  }

  componentDidUpdate() {
    this.updateDebouncedProps()
  }

  render() {
    const { component: Component, options, ...rest } = this.props
    const debouncedProps = {}
    options.props.forEach(item => {
      debouncedProps[item.name] = this.state[item.name]
      delete rest[item.name]
    })

    return <Component {...rest} {...debouncedProps} />
  }
}

const withDebouncedProps = (component, options) => {
  return props => {
    return <DebouncedProps {...props} component={component} options={options} />
  }
}

export default withDebouncedProps
