import { drawerActions } from '../actions'
const { OPEN_MENU_DRAWER, CLOSE_MENU_DRAWER } = drawerActions

const initialState = {
  menuDrawerOpen: false
}

const categories = (state = initialState, action = '') => {
  switch (action.type) {
    case OPEN_MENU_DRAWER:
      return { ...state, menuDrawerOpen: true }
    case CLOSE_MENU_DRAWER:
      return { ...state, menuDrawerOpen: false }
    default:
      return state
  }
}

export default categories
