import React from 'react'
import { TextBlock, RoundShape } from 'react-placeholder/lib/placeholders'
import Divider from '@material-ui/core/Divider'

const LoadMore = (
  <div>
    {[1, 2].map((item, index) => (
      <React.Fragment key={index}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 22px',
            height: 120,
            justifyContent: 'center'
          }}
        >
          <RoundShape
            style={{ width: 48, height: 48, flex: 'none', marginRight: 16 }}
            color="#d7d7d7"
          />
          <div style={{ flex: 1 }}>
            <TextBlock rows={2} color="#d7d7d7" style={{ marginTop: -15 }} />

            <TextBlock
              rows={4}
              color="#d7d7d7"
              style={{ height: 20, marginTop: 10, marginBottom: 10 }}
            />
          </div>
        </div>
        {index !== 1 && <Divider />}
      </React.Fragment>
    ))}
  </div>
)

export default LoadMore
