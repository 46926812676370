import escapeStringRegexp from 'escape-string-regexp'
const { REACT_APP_IFRAME_WHITELIST } = process.env

const isInWhiteList = documentReferrer => {
  const formattedDomain = (documentReferrer || '')
    .split('/')
    .slice(0, 3)
    .join('/')

  const whiteList = REACT_APP_IFRAME_WHITELIST.split('|')

  return !!whiteList.find(item => {
    const regexp = new RegExp(
      `^(https?:\/\/(?:.+\.)?(${escapeStringRegexp(item)})(?::\d{1,5})?\/?)$`
    )
    return regexp.test(formattedDomain)
  })
}

export default isInWhiteList
