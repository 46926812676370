/* eslint-disable react/no-multi-comp */

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'

const styles = theme => ({
  avatar: {
    width: 70,
    height: 70,
    background: '#5d5c5c',
    color: '#fff',
    fontSize: '1.3rem',
    margin: 'auto'
  }
})

class AuthorImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false
    }
  }

  handleImageError = () => {
    this.setState({
      error: true
    })
  }

  getFallBackImage = (src, name = '') => {
    const { error } = this.state
    let initials = name
      .trim()
      .split(' ')
      .map(item => item[0])
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    return src && !error ? '' : initials
  }

  render() {
    const { classes, author = {} } = this.props

    const fallbackImage = this.getFallBackImage(author.foto, author.nome)

    return (
      <Avatar
        alt={author.nome}
        src={author.foto}
        onError={this.handleImageError}
        style={{ background: author.foto ? '#999' : '#5d5c5c' }}
        className={classes.avatar}
      >
        {fallbackImage}
      </Avatar>
    )
  }
}

export default withStyles(styles)(AuthorImage)
