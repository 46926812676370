import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Query from 'react-apollo/Query'
import { faqQueries } from '../graphql/queries'
import OnlyOnline from '../components/OnlyOnline'
import ErrorHandling from '../components/ErrorHandling'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  header: {
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 20
  },
  wrapperPanels: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    paddingBottom: 20
  },
  progressWrapper: {
    height: 'calc(100vh - 54px)',
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  }
})

const Faq = props => {
  const { classes } = props
  return (
    <OnlyOnline>
      <Query query={faqQueries.GET_FAQ} fetchPolicy={'cache-and-network'}>
        {({ loading, error, data = {}, refetch, networkStatus }) => {
          const { faq = [] } = data

          if (error) {
            return (
              <div className={classes.root}>
                <ErrorHandling
                  error={error}
                  refetch={() => refetch()}
                  networkStatus={networkStatus}
                />
              </div>
            )
          }

          if (loading) {
            return (
              <div className={classes.progressWrapper}>
                <CircularProgress className={classes.progress} size={30} />
              </div>
            )
          }

          return (
            <div className={classes.root}>
              <Typography className={classes.header} variant={'title'}>
                {'Perguntas frequentes'}
              </Typography>
              <div className={classes.wrapperPanels}>
                {faq.sort((a, b) => a.sequencia - b.sequencia).map(item => (
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>{item.pergunta}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>{item.resposta}</Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            </div>
          )
        }}
      </Query>
    </OnlyOnline>
  )
}

export default withStyles(styles)(Faq)
