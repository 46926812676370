import gql from 'graphql-tag'

export const ADD_FAVORITE = gql`
  mutation addFavorite($content: String!) {
    addFavorite(content: $content) {
      _id
      tipo
      titulo
      principioAtivo
    }
  }
`

export const REMOVE_FAVORITE = gql`
  mutation removeFavorite($content: String!) {
    removeFavorite(content: $content)
  }
`
