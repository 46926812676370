import gql from 'graphql-tag'

export const GET_SPECIALTIES = gql`
  query {
    specialties {
      value
      label
    }
  }
`
