/**
 * @param {string} text - texto que será reduzido
 * @param {number} maxLength  - máximo de caracteres
 * @param {boolean} fallback - truncar somente caso não houver suporte por css
 *
 * @return {string} - retorna a string reduzida com "…" no final
 *
 * @example truncateEllipsis("Lorem ipsum dolor", 3)
 * @returns "Lor..."
 *
 * @example truncateEllipsis("Lorem ipsum", 20)
 * @returns "Lorem ipsum"
 */

const lineClampSupports = () => CSS && CSS.supports && CSS.supports('-webkit-line-clamp', '2')

export const truncateEllipsis = function(text = '', maxLength, fallback = false) {
  if (text === null) {
    return ''
  }
  if (fallback && lineClampSupports()) {
    return text
  }
  if (!maxLength) {
    return `${text}`
  } else {
    return text.length > maxLength ? `${text.substring(maxLength, 0)}…` : text
  }
}
