let _client

function setApolloProvider(apolloProviderRef) {
  const { client } = apolloProviderRef.props
  _client = client
}

function clearCache() {
  _client.clearStore()
}

export default {
  setApolloProvider,
  clearCache
}
