import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme => ({
  paper: {
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper
  },
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  header: {
    padding: '24px 24px 8px',
    textAlign: 'center',
    '& > *': {
      color: '#0080E2',
      fontSize: 24
    }
  },
  message: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: '1em'
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class UpdatePasswordAlertDialog extends React.Component {
  handleOk = () => {
    this.props.onClose()
  }

  render() {
    const { value, searchOrder, classes, firstLogin, message, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        TransitionComponent={Transition}
        className={classes.root}
        {...other}
      >
        <DialogTitle className={classes.header}>Bem-vindo ao Interdrugs!</DialogTitle>
        <DialogContent>
          <Typography className={classes.message}>
            Para o seu primeiro acesso, precisamos que você crie uma nova senha.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} variant={'contained'} onClick={this.handleOk}>
            Criar nova senha
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(UpdatePasswordAlertDialog)
