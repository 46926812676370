import React, { Component } from 'react'
import withRouter from 'react-router-dom/withRouter'
import Route from 'react-router-dom/Route'
import { AnimatedSwitch } from 'react-router-transition'
import { Login, NotFound, Layout, Profile, DirectAuth, Walkthrough } from '../containers'
import PrivateRoute from '../components/PrivateRoute'
import MedicationInteraction from '../containers/MedicationInteraction'
import InformationInteraction from '../containers/InformationInteraction'

const bounceTransitionGo = {
  // start in a transparent, uptranslated state
  atEnter: {
    translate: -100
  },
  // leave in a transparent, downtranslated state
  atLeave: {
    translate: 100
  },
  // and rest at an opaque, normally-translated state
  atActive: {
    translate: 0
  }
}

const bounceTransitionBack = {
  // start in a transparent, uptranslated state
  atEnter: {
    translate: 100
  },
  // leave in a transparent, downtranslated state
  atLeave: {
    translate: -100
  },
  // and rest at an opaque, normally-translated state
  atActive: {
    translate: 0
  }
}

const opacityTransition = {
  // start in a transparent, uptranslated state
  atEnter: {
    opacity: 0,
    translate: 0
  },
  // leave in a transparent, downtranslated state
  atLeave: {
    opacity: 0,
    translate: 0
  },
  // and rest at an opaque, normally-translated state
  atActive: {
    opacity: 1,
    translate: 0
  }
}

function mapStyles(styles) {
  return {
    opacity: styles.opacity ? styles.opacity : 1,
    transform: `translateX(${styles.translate}%)`
  }
}

class AppRoutes extends Component {
  render() {
    const { location } = this.props

    const getAnimationTransition = pathname => {
      if (pathname.includes('login')) {
        return opacityTransition
      }
      return location.pathname.includes('conteudo') ||
        location.pathname.includes('meu-perfil') ||
        location.pathname.includes('medicamentos-interacoes') ||
        location.pathname.includes('bulario') ||
        location.pathname.includes('informacoes-interacoes')
        ? bounceTransitionBack
        : bounceTransitionGo
    }

    const { atEnter, atLeave, atActive } = getAnimationTransition(location.pathname)

    return (
      <AnimatedSwitch
        atEnter={atEnter}
        atLeave={atLeave}
        atActive={atActive}
        mapStyles={(...args) => {
          return mapStyles(...args)
        }}
        className="switch-wrapper-app"
      >
        <Route exact path="/login" component={Login} />
        <Route exact path="/auth" component={DirectAuth} />
        <Route exact path="/tutorial" component={Walkthrough} />
        <PrivateRoute exact path="/meu-perfil" component={Profile} />
        <PrivateRoute
          exact
          path="/medicamentos-interacoes/:term"
          component={MedicationInteraction}
        />
        <PrivateRoute
          exact
          path="/informacoes-interacoes/:term"
          component={InformationInteraction}
        />
        <PrivateRoute path="/" component={Layout} />
        <Route component={NotFound} />
      </AnimatedSwitch>
    )
  }
}

export default withRouter(AppRoutes)
