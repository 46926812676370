import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Link from 'react-router-dom/Link'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { medicationListActions } from '../../../actions'
import MedicationPlaceholder from '../MedicationPlaceholder'
import ClearAllMedications from '@material-ui/icons/ClearAll'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import IconButton from '@material-ui/core/IconButton'
import { startAnimation } from '../../../fixGestureNavigationIOS'
import SwitchDesktopMobile from '../../../components/SwitchDesktopMobile'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    paddingBottom: 15,
    overflow: 'auto'
  },
  list: {
    paddingTop: 0,
    width: '100%'
  },
  item: {
    flex: 1,
    paddingLeft: 22,
    paddingRight: 22,
    alignItems: 'center',
    wordBreak: 'break-word',
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemTitle: {
    color: '#5d5c5c',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical'
    }
  },
  resultPlaceholderWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  itemDescription: {
    fontSize: 14,
    marginTop: 4,
    '& em': {
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    '& > div': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 3,
      '-webkit-box-orient': 'vertical'
    }
  },
  itemIconButton: {
    marginLeft: 'auto',
    marginTop: 4,
    '& em': {
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    '& > div': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 3,
      '-webkit-box-orient': 'vertical'
    }
  },
  title: {
    color: '#505152',
    padding: 0,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22,
    paddingRight: 0,
    display: 'flex',
    minHeight: 46,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  numberResults: {
    color: '#5d5c5c',
    fontSize: 12,
    fontWeight: 'bold'
  },
  optionsButton: {
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 5,
    marginRight: 10,
    minHeight: 28,
    '&:hover, &:focus': {
      background: '#19A97A'
    }
  },
  optionsButtonIcon: {
    fontSize: 22
  },
  switchControlLabel: {
    '& > span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  optionsButton: {
    color: '#fff',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 5,
    marginRight: 10,
    minHeight: 28,
    background: '#6EBD8F'
  },
  optionsButtonIcon: {
    fontSize: 22
  },
  analyzeButton: {
    textAlign: 'center',
    fontSize: 16,
    width: '280px',
    boxShadow: 'none',
    textTransform: 'none',
    display: 'block',
    margin: 'auto',
    marginBottom: '-42px',
    padding: '15px',
    textTransform: 'uppercase',
    '@media only screen and (min-width: 800px)': {
      marginBottom: '80px'
    }
  }
})

class ResultSearch extends React.Component {
  onConfirm = ({ id, nome }) => {
    const currentMedication = this.props.selectedList.find(item => item.id === id)

    if (currentMedication) {
      this.props.removeMedication({ id })
    } else {
      this.props.addMedication({ id, nome })
    }
  }
  render() {
    const { classes } = this.props
    const listResult = this.props.selectedList || []
    if (!listResult.length) {
      return <MedicationPlaceholder />
    }
    return (
      <>
        <div style={{ marginTop: '10px' }}>
          <div className={classes.title}>
            <span className={classes.numberResults}>{listResult.length || 0} medicamentos</span>
            <div style={{ float: 'right' }}>
              <Button
                variant="extendedFab"
                color="secondary"
                aria-label="Limpar lista"
                onClick={() => {
                  this.props.clearMedicationList()
                }}
                className={classes.optionsButton}
              >
                <ClearAllMedications className={classes.optionsButtonIcon} />
                Limpar lista
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.root}>
          <List className={classes.list}>
            {(listResult || []).map(({ id, nome }) => {
              return (
                <div key={id}>
                  <ListItem className={classes.item} component={'div'}>
                    <div>
                      <div className={classes.itemDescription}>
                        <span>{(nome || '').toUpperCase()}</span>
                      </div>
                    </div>
                    <div className={classes.itemIconButton}>
                      <IconButton color="primary" onClick={() => this.onConfirm({ id, nome })}>
                        {mountButton({ id }, this.props.selectedList)}
                      </IconButton>
                    </div>
                  </ListItem>
                </div>
              )
            })}
          </List>
        </div>
        <SwitchDesktopMobile desktop>
          <Button
            className={classes.analyzeButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              // startAnimation()
              this.props.interactionsConsult(
                (this.props.selectedList || []).map(item => (item || {}).id).join(',')
              )
            }}
          >
            Analisar
          </Button>
        </SwitchDesktopMobile>
      </>
    )
  }
}

const mountButton = ({ id }, listMedication) => {
  let selected = false

  listMedication.map(item => {
    if (item.id === id) selected = true
  })
  return <RemoveCircleIcon style={{ color: '#ef2642' }} />
}

const mapStateToProps = state => {
  return {
    selectedList: state.medicationList.selectedList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeMedication: medication => {
      dispatch(medicationListActions.removeMedication(medication))
    },
    clearMedicationList: () => {
      dispatch(medicationListActions.clearMedicationList())
    },
    interactionsConsult: medications => {
      dispatch(medicationListActions.interactionsConsult(medications))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ResultSearch))
