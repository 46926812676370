import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { Detector } from 'react-detect-offline'
import SignalWifiOff from '@material-ui/icons/SignalWifiOff'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    background: '#F7F7F7'
  },
  title: {
    color: '#505152',
    padding: 8,
    fontSize: 28
  },
  subTitle: {
    color: '#505152',
    padding: 8,
    fontSize: 20
  },
  wifiOffIcon: {
    fontSize: 100,
    color: '#505152'
  }
})

const OnlyOnline = ({ classes, children }) => {
  return (
    <Detector
      polling={{ enabled: false }}
      render={({ online }) =>
        online ? (
          children
        ) : (
          <div className={classes.root}>
            <SignalWifiOff className={classes.wifiOffIcon} />
            <Typography className={classes.title} variant={'title'}>
              Ooops
            </Typography>
            <Typography className={classes.subTitle}>Percebemos que você está offline.</Typography>
          </div>
        )
      }
    />
  )
}

export default withStyles(styles, { withTheme: true })(OnlyOnline)
