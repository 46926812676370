import gql from 'graphql-tag'

export const SEARCH_PRESCRIPTION = gql`
  query medicineLeaflet($term: String!, $offset: Int) {
    medicineLeaflet(term: $term, offset: $offset) {
      total
      items {
        _id
        principioAtivo
      }
    }
  }
`
