import gql from 'graphql-tag'

export const GET_USER_INFOS = gql`
  query favorites_notes {
    favorites {
      _id
      tipo
      titulo
      principioAtivo
    }

    notes {
      anotacao
      conteudo {
        _id
        titulo
        tipo
        principioAtivo
      }
    }
  }
`
