import React from 'react'
import { RectShape } from 'react-placeholder/lib/placeholders'

const wrapperStyle = {
  margin: 23
}

const Content = (
  <div style={wrapperStyle}>
    <React.Fragment>
      <RectShape
        color="#d7d7d7"
        style={{
          width: '100%',
          height: '1rem',
          flex: 'none',
          marginRight: 16,
          color: '#505152',
          marginTop: 38
        }}
      />
      <RectShape
        color="#d7d7d7"
        style={{
          width: '90%',
          height: '1rem',
          flex: 'none',
          marginRight: 16,
          color: '#505152',
          marginTop: 10
        }}
      />
      <div style={{ display: 'flex', marginTop: 23, flexWrap: 'wrap' }}>
        <RectShape
          color="#d7d7d7"
          style={{
            width: 'calc(23% + 1rem)',
            height: '1rem',
            flex: 'none',
            marginRight: 16,
            color: '#505152',
            marginTop: 10
          }}
        />
        <RectShape
          color="#d7d7d7"
          style={{
            width: 'calc(23% + 1rem)',
            height: '1rem',
            flex: 'none',
            marginRight: 16,
            color: '#505152',
            marginTop: 10
          }}
        />
        <RectShape
          color="#d7d7d7"
          style={{
            width: 'calc(23% + 1rem)',
            height: '1rem',
            flex: 'none',
            marginRight: 16,
            color: '#505152',
            marginTop: 10
          }}
        />
        <RectShape
          color="#d7d7d7"
          style={{
            width: 'calc(23% + 1rem)',
            height: '1rem',
            flex: 'none',
            marginRight: 16,
            color: '#505152',
            marginTop: 10
          }}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 23 }}>
        <RectShape
          color="#d7d7d7"
          style={{
            width: '100%',
            height: 0,
            paddingBottom: '50%',
            flex: 'none',
            marginRight: 16,
            color: '#505152',
            marginTop: 10
          }}
        />
      </div>
    </React.Fragment>
  </div>
)

export default Content
