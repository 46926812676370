import { safeLocalStorage } from './utils'
const lastCheckForUpdateKey = 'LAST_CHECK_FOR_UPDATE'

const onUpdate = registration => {
  document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }))
}

const swUpdate = registration => {
  registration.update()
  safeLocalStorage().setItem(lastCheckForUpdateKey, Date.now())
}

const checkForUpdate = registration => {
  const now = Date.now()
  const lastCheck = safeLocalStorage().getItem(lastCheckForUpdateKey)

  if (lastCheck) {
    const updateTime = now - lastCheck

    if (updateTime >= 1.2e6) {
      // 20 minutos
      swUpdate(registration)
    }
  } else {
    swUpdate(registration)
  }
}

const onRegistered = registration => {
  setInterval(() => {
    checkForUpdate(registration)
  }, 10000) // 10 segundos
}

export default {
  onUpdate,
  onRegistered
}
