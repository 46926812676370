import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CircularProgress from '@material-ui/core/CircularProgress'
import withRouter from 'react-router-dom/withRouter'
import Query from 'react-apollo/Query'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Link from 'react-router-dom/Link'
import Snackbar from '@material-ui/core/Snackbar'
import ErrorHandling from '../components/ErrorHandling'
import OnlyOnline from '../components/OnlyOnline'
import { startAnimation } from '../fixGestureNavigationIOS'
import { shortMedicationInteractionQueries } from '../graphql/queries'
import ResultItemIcon from './ResultItemIcon'
import MaiorIcon from '../icons/svg/Maior.svg'
import ModeradaIcon from '../icons/svg/Moderada.svg'
import MenorIcon from '../icons/svg/Menor.svg'
import InteractionPlaceholder from './showInteractionsPlaceholder'
import { medicationListActions } from '../actions'
import { connect } from 'react-redux'
import SearchResultListPlaceholder from '../components/Placeholder/SearchResult/List'
import * as loggerClient from '../services/loggerClient'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    paddingBottom: 15,
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    background: '#f7f7f7',
    maxWidth: '360px',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    '@media only print': {
      paddingTop: 0,
      display: 'none'
    }
  },
  list: {
    paddingTop: 0,
    width: '100%',
    overflow: 'auto'
  },
  placeholders: {
    width: '100%',
    flex: 1,
    marginTop: '150px',
    display: 'flex',
    minHeight: '100%',
    boxSizing: 'border-box',
    flexDirection: 'column',
    paddingBottom: 15,
    minHeight: '100%'
  },
  appBar: {
    backgroundColor: '#5d5c5c',
    zIndex: theme.zIndex.drawer + 1
  },
  title: {
    color: '#505152',
    padding: 0,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    width: '100%',
    height: 'auto',
    // minHeight: 46,
    alignItems: 'center',
    justifyContent: 'center',
    '& h4': {
      margin: 0,
      padding: 0,
      marginLeft: '26px'
    },
    '& ~ ul': {
      overflowY: 'scroll'
    },
    '@media only screen and (min-width: 800px)': {
      '& ~ ul:last-child' :{
        marginBottom: '55px'
      }
    }
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  toolBar: {
    padding: '0 8px'
  },
  menuLink: {
    display: 'flex',
    textDecoration: 'none',
    padding: '12px 16px',
    '& > svg': {
      color: 'rgba(0, 0, 0, 0.54) !important'
    },
    '&.menu-link-active': {
      background: '#eee',
      '& > div span': {
        color: '#0080E2'
      },
      '& > svg': {
        color: '#0080E2 !important'
      }
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  menuButton: {
    margin: '0'
  },
  toolbarUser: {
    color: 'inherit',
    fontSize: '.9rem',
    display: 'block'
  },
  toolbarMail: {
    color: 'inherit',
    fontSize: '.7rem'
  },
  gravity: {
    color: '#5d5c5c',
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: '8px',
    marginTop: '12px'
  },
  actionButtonIcon: {
    width: 28,
    height: 28,
    marginRight: 2
  },
  gravityItensity: {
    color: '#fff',
    marginRight: '8px',
    maxWidth: '150px',
    maxHeight: '30px',
    padding: '0 7px',
    fontSize: '12px',
    cursor: 'default'
  },
  itemList: {
    margin: 'auto',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '93%',
    height: 'auto',
    cursor: 'pointer'
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px'
  },
  childrenWrapper: {
    width: '100%',
    height: 'calc(100% - 55px)',
    '-webkit-overflow-scrolling': 'touch' /* Lets it scroll lazy */,
    background: '#f7f7f7',
    '@media only screen and (max-width: 900px) and (orientation: landscape)': {
      height: 'calc(100% - 47px)'
    }
  },
  singleMessage: {
    display: 'block',
    maxWidth: '250px',
    whiteSpace: 'normal',
    '& > span': {
      color: '#000',
      fontSize: '16px',
      fontWeight: '400'
    },
    '& > div': {
      color: '#000',
      fontSize: '14px',
      fontWeight: '400'
    },
    '& > div span': {
      color: '#9e9e9e',
      lineHeight: '1.4',
      fontWeight: '400'
    }
  },
  userHeader: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  itemText: {
    '& > *': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
      marginLeft: '4px'
    },
    '& > span': {
      color: '#000',
      fontSize: '16px',
      fontWeight: '400'
    }
  },
  lockIcon: {
    marginRight: 5,
    fontSize: 18
  }
})

class shortMedicationInteraction extends React.Component {
  constructor(props) {
    super(props)
    this.contentWrapperRef = React.createRef()
    this.state = {
      snackMessage: '',
      params: '',
      listInteraction: []
    }
  }

  getDataInteraction = ({ _id, medOne, medTwo }) => {
    const obj = {
      id: _id,
      medOne,
      medTwo
    }

    this.setState({
      listInteraction: [...this.state.listInteraction, obj]
    })
  }

  renderSpinner = () => {
    const { classes } = this.props
    return <CircularProgress className={classes.progress} size={30} />
  }

  renderInteraction = interaction => {
    const { classes } = this.props

    if(!!interaction){
      return (
        <div className={classes.container}>
          <div className={classes.title}>
            <div className={classes.gravity}>
              <h4>GRAVIDADES</h4>
              <div
                className={classes.gravities}
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  marginLeft: 26,
                  marginRight: 26,
                  width: '100%'
                }}
              >
                <Button
                  aria-label="Filtrar"
                  style={this.returnColors('Maior', interaction)}
                  className={classes.gravityItensity}
                >
                  <img src={MaiorIcon} className={classes.actionButtonIcon} />
                  Maior
                </Button>
  
                <Button
                  aria-label="Filtrar"
                  className={classes.gravityItensity}
                  style={this.returnColors('Moderada', interaction)}
                >
                  <img src={ModeradaIcon} className={classes.actionButtonIcon} />
                  Moderada
                </Button>
  
                <Button
                  aria-label="Filtrar"
                  style={this.returnColors('Menor', interaction)}
                  className={classes.gravityItensity}
                >
                  <img src={MenorIcon} className={classes.actionButtonIcon} />
                  Menor
                </Button>
              </div>
            </div>
  
          </div>
          {interaction.length > 0 && interaction[0].nome != null ? (
            <List className={classes.list}>
            {interaction
              .sort(function(a, b) {
                return a.gravidadeOrdem > b.gravidadeOrdem
                  ? 1
                  : b.gravidadeOrdem > a.gravidadeOrdem
                    ? -1
                    : 0
              })
              .map(({ _id, gravidade, medicamentos, nome, corGravidade }, index) => {
                const classifications = nome.split(' ')
                const classPrint = classifications.map(item => item.trim())
                return (
                    <Button
                      style={{
                        width: '100%',
                        backgroundColor: 'transparent',
                        marginRight: '0px',
                        float: 'left'
                      }}
                      onClick={() => {
                        let data = _id + ',' + medicamentos
                        this.props.resetInformations()
                        this.props.interactionsInformationsConsult(data)
                        loggerClient.onShowInteractionResult({
                          correspondencias: interaction.length,
                          medicamentos: this.props.selectedList.map(item => item.id),
                          conteudo: _id,
                          posicao: index + 1
                        })
                        this.props.storePrescriptionInformation(medicamentos)
                      }}
                    >
                      <ListItem className={classes.itemList} component={'div'}>
                        <ResultItemIcon
                          gravidade={gravidade}
                          corGravidade={corGravidade}
                          id={gravidade}
                        />
                        <div style={{ maxHeight: '100%' }}>
                          <div className={classes.itemText}>
                            <span style={{ display: 'flex', overflow: 'auto' }}>
                              {classPrint.join(' ')}
                            </span>
                          </div>
                        </div>
                      </ListItem>
                    </Button>
                  )
                })
              }
            </List>
          ) : (
            <div className={classes.placeholders}>
              <InteractionPlaceholder />
            </div>
          )}
        </div>
      )
    }else{
      return <></>
    }
  
  }

  openSnackMessage = message => {
    this.setState({
      snackMessage: message
    })
  }

  closeSnackMessage = () => {
    this.setState({
      snackMessage: ''
    })
  }

  render() {
    const { classes, storePrescriptionInformation } = this.props
    this.state.params = this.props.consultInteractionsList
    const searchTerm = this.props.consultInteractionsList

    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={shortMedicationInteractionQueries.SEARCH_SHORT_INTERACTION}
        variables={{
          term: searchTerm.split(',')
        }}
        onCompleted={(data = {}) => {
          const { shortMedicationsInteractions = {} } = data
          if(shortMedicationsInteractions.length){
          loggerClient.onAnalyzeMedicationsInteractions({
            medicamentos: this.props.selectedList.map(item => item.id),
            correspondencias: shortMedicationsInteractions.length
          })
        }
        }}
      >
        {({ loading, error, data, refetch, networkStatus, fetchMore }) => {
          if (error) {
            return (
              <ErrorHandling
                error={error}
                refetch={() => refetch()}
                networkStatus={networkStatus}
              />
            )
          }
          return (
            <div className={classNames(classes.root, 'transition-item detail-page')}>
              <AppBar position="absolute" className={classNames(classes.appBar)}>
                <Toolbar disableGutters={true} className={classNames(classes.toolBar, 'container yAxisCleared')}>
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    onClick={() => {
                      startAnimation()
                      this.props.changeConsultInteraction()
                      this.props.resetInformations()
                    }}
                    className={classNames(classes.menuButton)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant="title" color="inherit" noWrap>
                    Interações
                  </Typography>
                </Toolbar>
              </AppBar>
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <OnlyOnline>
                  <div className={classNames(classes.childrenWrapper)}>
                    <div className={'container'}>
                      {loading
                        ? this.renderSpinner()
                        : this.renderInteraction(data.shortMedicationsInteractions) || []}
                    </div>
                  </div>
                </OnlyOnline>
              </main>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3500}
                onClose={() => {
                  this.closeSnackMessage()
                }}
                open={!!this.state.snackMessage}
                ContentProps={{
                  'aria-describedby': 'login-error'
                }}
                message={<span id="login-error">{this.state.snackMessage}</span>}
              />
            </div>
          )
        }}
      </Query>
    )
  }

  returnColors(color, data) {
    switch (color) {
      case 'Maior':
        if (data[0].corGravidadeMaior !== null && data[0].corGravidadeMaior !== '') {
          return { backgroundColor: data.corGravidadeMaior, maxWidth: '90px', width: '100%' }
        } else {
          return { backgroundColor: '#DC143C', maxWidth: '90px', width: '100%' }
        }
      case 'Moderada':
        if (data[0].corGravidadeModerada !== null && data[0].corGravidadeModerada !== '') {
          return { backgroundColor: data[0].corGravidadeModerada }
        } else {
          return { backgroundColor: '#FFA500' }
        }
      case 'Menor':
        if (data[0].corGravidadeMenor !== null && data[0].corGravidadeMenor !== '') {
          return { backgroundColor: data[0].corGravidadeMenor, maxWidth: '90px', width: '100%' }
        } else {
          return { backgroundColor: '#3CB371', maxWidth: '90px', width: '100%' }
        }
    }
  }
}

const mapStateToProps = state => {
  return {
    consultInteractionsList: state.medicationList.consultInteractionsList,
    interactionsInformations: state.medicationList.interactionsInformations,
    selectedList: state.medicationList.selectedList,
    prescriptionsList: state.medicationList.prescriptionsList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeConsultInteraction: () => {
      dispatch(medicationListActions.changeConsultInteraction())
    },
    resetInformations: () => {
      dispatch(medicationListActions.resetInformations())
    },
    interactionsInformationsConsult: informations => {
      dispatch(medicationListActions.interactionsInformationsConsult(informations))
    },
    storePrescriptionInformation: interaction => {
      dispatch(medicationListActions.storeInformations(interaction))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(shortMedicationInteraction))
